import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect, useRef, useState} from "react";
import BaseButton from "../../components/base/Button";
import CertificateGenerator from "./certificate";
import axios from "../../libs/axios";
import {useNavigate} from "react-router-dom";
import RoleEnum from "../../components/enums/RoleEnum";
import {Button} from "@headlessui/react";
import Tickets from "../../components/dashboard/dashboard/Tickets";
import TicketStatusEnum from "../../components/enums/TicketStatusEnum";
import LoadingPlaceholderSection from "../../components/sections/LoadingPlaceholderSection";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";

const QuickActionButton = ({ href, children }) => (
    <a 
        href={href}
        className="group flex items-center justify-between px-6 py-4 rounded-lg bg-gradient-to-r from-[#468ef9] to-[#0c66ee] 
            hover:from-[#3d7ed9] hover:to-[#0a56c7] transition-all duration-300 transform hover:-translate-y-0.5"
    >
        <span className="text-white font-semibold">{children}</span>
        <svg className="w-5 h-5 text-white transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
        </svg>
    </a>
);

const TicketButton = ({ onClick, children, variant = "primary" }) => (
    <button
        onClick={onClick}
        className={`inline-flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg
            transition-all duration-200 hover:-translate-y-0.5 shadow-sm hover:shadow
            ${variant === "primary" 
                ? "bg-gradient-to-r from-[#468ef9] to-[#0c66ee] hover:from-[#3d7ed9] hover:to-[#0a56c7] text-white" 
                : "bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 text-white"
            }`}
    >
        {variant === "primary" ? (
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
        ) : (
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
        )}
        {children}
    </button>
);

const Dashboard = () => {
    const { user } = useAuth({ middleware: 'auth' })
    const [canStartQuiz, setCanStartQuiz] = useState(false);
    const [certificateOne, setCertificateOne] = useState(false);
    const [hasAccessRequested, setHasAccessRequested] = useState(undefined);
    const [tickets, setTickets] = useState([]);
    const [newTicketOpen, setNewTicketOpen] = useState(false);
    const [showClosedTickets, setShowClosedTickets] = useState(false);
    const [isLoadingTest, setIsLoadingTest] = useState(true);

    const { csrf } = useAuth();

    let navigate = useNavigate();
    const apiCallMadeRef = useRef(false);

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Dashboard"

        const fetchData = async () => {
            if (apiCallMadeRef.current) return;
            apiCallMadeRef.current = true;

            try {
                await csrf();

                const tickets = await getUserTickets().catch(error => {});
                setTickets(tickets);

                if (tickets) {
                    const accessRequestedTicket = tickets.filter(ticket => ticket.category === "REQUEST_TEST_ACCESS" && ticket.status !== TicketStatusEnum.Closed);
                    setHasAccessRequested(accessRequestedTicket.length > 0);
                }

                const canStartQuizResponse = await getCanStartQuiz().catch(error => {});
                setCanStartQuiz(canStartQuizResponse);

                const hasCertificateOneResponse = await getHasCertificateOne().catch(error => {});
                setCertificateOne(hasCertificateOneResponse);
                setIsLoadingTest(false);

            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };

        fetchData();

    }, []);

    const getCanStartQuiz = async () => {
        try {
            const response = await axios.get("/quiz/user-can-start");
            return response.data.userCanStartQuiz;
        }catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                navigate('/login');
                window.location.reload();
            }
            return 0;
        }
    }

    const getHasCertificateOne = async () => {
        try {
            const response = await axios.post("/quiz/verify-certificate");
            return response.data;
        }catch(error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                navigate('/login');
                window.location.reload();
            }
            return 0;
        }
    }

    const requestTestAccess = async () => {
        try {
            await csrf();
            const response = await axios.post(`/quiz/request-access`, { user_id: user.id });
            if (response.data.category === "REQUEST_TEST_ACCESS") {
                setHasAccessRequested(true);

                setTickets(prevTickets => [...prevTickets, response.data]);
            }
            return response.data;
        }catch(error) {
            return 0;
        }
    }

    const getUserTickets = async () => {
        try {
            const response = await axios.get(`/ticket/get`).catch(error => {});
            return response.data;
        }catch(error) {
            navigate('/login');
        }
    }

    const NewTicketForm = () => {
        const [title, setTitle] = useState('');
        const [description, setDescription] = useState('');

        const onSubmit = async (event) => {
            event.preventDefault();

            try {
                await csrf();
                const response = await axios.post('/ticket/new', { title, description })
                    .then(response => {
                        setTickets(prevTickets => [...prevTickets, response.data]);
                        setTitle('');
                        setDescription('');
                        setNewTicketOpen(false);
                    })
                    .catch(error => {});
            } catch (error) {
                // Handle error
            }
        };

        return(
            <div>
                <p>Please note: you can only open 2 tickets.</p>
                <form onSubmit={onSubmit} className="mt-4">
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                    <input
                        id="title"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="mt-1 block w-full rounded-md bg-gray-100 p-3 border-transparent border focus:border-gray-500
                                                                focus:bg-white focus:ring-0"
                    />
                    <label htmlFor="description" className="block mt-4 text-sm font-medium text-gray-700">Description</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className={`mt-1 block w-full rounded-md bg-gray-100 p-5 border-transparent border focus:border-gray-500 focus:bg-white focus:ring-0`}
                    />
                    <button type="submit" disabled={!title || !description}
                            className={`mt-4 ${(!title || !description) ? 'bg-gray-300 text-white' : 'bg-blue-500 text-white hover:bg-blue-600'} px-4 py-2 rounded`}>
                        Submit
                    </button>
                </form>
            </div>
        );
    }

    const CurrentTicketSection = () => {
        return(
            <div>
                {showClosedTickets ?
                <>
                    <p>Below you can find your last closed tickets.</p>
                    <Tickets tickets={tickets}
                             showOnlyStatus={TicketStatusEnum.Closed}
                             showMax={5} />
                </> :
                <>
                    <p>Below you can find your last open tickets.</p>

                    {user.role === RoleEnum.Registered ?
                        <Tickets tickets={tickets}
                                 filterStatus={TicketStatusEnum.Closed}
                                 filterCategory={"REQUEST_TEST_ACCESS"}
                                 showMax={5}/>
                        :
                        <Tickets tickets={tickets}
                            filterStatus={TicketStatusEnum.Closed}
                            showMax={5} />
                    }
                </>}
            </div>
        );
    }

    const HideShowTickets = () => {
        const closedTicketsCount = tickets.filter(ticket => ticket.status === TicketStatusEnum.Closed).length;
        
        return (
            <button
                onClick={() => setShowClosedTickets(!showClosedTickets)}
                className={`inline-flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg
                    transition-all duration-200 hover:-translate-y-0.5 shadow-sm hover:shadow
                    ${showClosedTickets 
                        ? "bg-gradient-to-r from-[#468ef9] to-[#0c66ee] hover:from-[#3d7ed9] hover:to-[#0a56c7] text-white"
                        : "bg-gray-100 hover:bg-gray-200 text-gray-700"
                    }`}
            >
                {showClosedTickets ? (
                    <>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                        Open tickets
                    </>
                ) : (
                    <>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                        Closed tickets
                        {closedTicketsCount > 0 && (
                            <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none 
                                bg-gray-200 text-gray-700 rounded-full"
                            >
                                {closedTicketsCount}
                            </span>
                        )}
                    </>
                )}
            </button>
        );
    };

    const CancelCreateTicket = () => {
        // Check if last ticket is closed
        const canCreateTicket = tickets && tickets.filter(ticket => ticket.status !== TicketStatusEnum.Closed).length < 2;
        
        if (!canCreateTicket) return null;
        
        return (
            <TicketButton 
                onClick={() => setNewTicketOpen(!newTicketOpen)}
                variant={newTicketOpen ? "secondary" : "primary"}
            >
                {newTicketOpen ? "Cancel ticket" : "New ticket"}
            </TicketButton>
        );
    };

    const ShowCertificateOrTest = () => {
        if (certificateOne.certificate === "valid") {
            return <CertificateGenerator name={certificateOne?.user} certificateId={certificateOne?.id}
                                         dateCompleted={certificateOne?.date}/>
        }

        if (certificateOne.certificate === "in-progress" || certificateOne.certificate === "failed") {
            return (
                <div className="space-y-6">
                    {certificateOne.certificate === "in-progress" ? (
                        <div className="space-y-4 bg-blue-50 border border-blue-100 rounded-lg p-6">
                            <div className="flex items-start gap-3">
                                <div className="p-2 bg-blue-100 rounded-full">
                                    <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <div className="space-y-2">
                                    <p className="font-medium text-blue-900">
                                        You currently have a <span className="font-bold">test in progress</span>!
                                    </p>
                                    <p className="text-blue-700">
                                        Make sure to keep the test window open and do not close it.
                                    </p>
                                    <p className="text-sm text-blue-600">
                                        If you did close it, please reach out to support or wait until the time runs out and come back for your result.
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : certificateOne.certificate === "failed" && (
                        <div className="space-y-4 bg-red-50 border border-red-100 rounded-lg p-6">
                            <div className="flex items-start gap-3">
                                <div className="p-2 bg-red-100 rounded-full">
                                    <svg className="w-5 h-5 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <div className="space-y-3">
                                    <div className="space-y-1">
                                        <p className="font-medium text-red-900">
                                            Test attempt failed
                                        </p>
                                        <p className="text-red-700">
                                            You attempted the CAIP - 1 (IPE) test on{' '}
                                            <span className="font-semibold">{certificateOne?.date}</span> and received a score of{' '}
                                            <span className="font-bold">{certificateOne?.score}%</span>.
                                        </p>
                                    </div>
                                    <div className="bg-white/50 rounded-lg p-4 border border-red-100">
                                        <p className="text-red-600">
                                            Next attempt available on{' '}
                                            <span className="font-semibold">{certificateOne?.retake_date}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    }

    return (
        user ? (
            <AppLayout
                header={
                    <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                        Dashboard
                    </h2>
                }
            >
                <div className="min-h-screen bg-gray-50/50 py-8">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        {/* Welcome Section */}
                        <div className="grid gap-6 mb-8 md:grid-cols-3">
                            <DashboardCard className="md:col-span-2">
                                <div className="flex items-center gap-6">
                                    <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] flex items-center justify-center">
                                        <span className="text-2xl font-bold text-white">
                                            {user?.name?.[0]?.toUpperCase()}
                                        </span>
                                    </div>
                                    <div>
                                        <h1 className="text-2xl font-bold text-gray-900">
                                            Welcome back, <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee]">{user?.name}</span>
                                        </h1>
                                        <p className="text-gray-600 mt-1">Track your progress in the Crafting AI Prompts Framework</p>
                                    </div>
                                </div>
                            </DashboardCard>

                            <div className="flex flex-col gap-3">
                                <QuickActionButton href="/documentation/framework">
                                    Framework Guide
                                </QuickActionButton>
                                <QuickActionButton href="/documentation/tools">
                                    Tools
                                </QuickActionButton>
                                {user?.role >= RoleEnum.Trainer ? (
                                    <QuickActionButton href="/prompt-library">
                                        Prompt Library
                                    </QuickActionButton>
                                ) : (
                                    <QuickActionButton href="/documentation/prompt-injections">
                                        Prompt Injections
                                    </QuickActionButton>
                                )}
                            </div>
                        </div>

                        {/* Main Content */}
                        <div className="grid gap-6 md:grid-cols-2">
                            {/* Left Column */}
                            <div className="space-y-6">
                                {isLoadingTest ? (
                                    <LoadingPlaceholderSection />
                                ) : user.role < RoleEnum.DefaultUser ? (
                                    <DashboardCard 
                                        title={
                                            <span className="flex items-center gap-2">
                                                <span>Certification Test</span>
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                                    Beta
                                                </span>
                                            </span>
                                        }
                                    >
                                        {hasAccessRequested !== undefined ? (
                                            hasAccessRequested ? (
                                                <div className="space-y-4">
                                                    <div className="p-4 bg-blue-50 border border-blue-100 rounded-lg">
                                                        <div className="flex items-start gap-3">
                                                            <div className="p-2 bg-blue-100 rounded-full shrink-0">
                                                                <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </div>
                                                            <div className="space-y-3">
                                                                <h3 className="text-lg font-semibold text-blue-900 pt-1 pl-2">
                                                                    Your test access request is being reviewed
                                                                </h3>
                                                                <p className="text-blue-700 text-sm gap-2 pl-2 pr-2">                                                                    
                                                                    Please check back in 1-3 days. We try to accept this request within 24 hours, but as you won't receive an email, you can check back here in 3 days to be sure.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Tickets 
                                                        tickets={tickets} 
                                                        category="REQUEST_TEST_ACCESS" 
                                                        filterStatus={TicketStatusEnum.Closed} 
                                                    />
                                                </div>
                                            ) : (
                                                <div className="space-y-4">
                                                    <p className="text-gray-600">
                                                        Ready to test your knowledge? Request access to the beta test below.
                                                    </p>
                                                    <button
                                                        onClick={() => requestTestAccess()}
                                                        className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] 
                                                            text-white font-medium rounded-lg transition-all duration-300 hover:-translate-y-0.5"
                                                    >
                                                        Request Access
                                                    </button>
                                                </div>
                                            )
                                        ) : (
                                            <LoadingPlaceholderSection />
                                        )}
                                    </DashboardCard>
                                ) : canStartQuiz ? (
                                    <DashboardCard 
                                        title={
                                            <span className="flex items-center gap-2">
                                                <span>Start Your Certification Test</span>
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                    Ready
                                                </span>
                                            </span>
                                        }
                                    >
                                        <div className="space-y-4">
                                            <p className="text-gray-600">
                                                You have been granted access to take the certification test. Click below to begin.
                                            </p>
                                            <button
                                                onClick={() => navigate('/dashboard/quiz')}
                                                className="group flex items-center justify-between px-6 py-4 rounded-lg bg-gradient-to-r from-[#468ef9] to-[#0c66ee] 
                                                    hover:from-[#3d7ed9] hover:to-[#0a56c7] transition-all duration-300 transform hover:-translate-y-0.5"
                                            >
                                                <span className="text-white font-semibold">Start Test</span>
                                                <svg className="w-5 h-5 text-white transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                                </svg>
                                            </button>
                                        </div>
                                    </DashboardCard>
                                ) : null}

                                {user.role >= RoleEnum.DefaultUser && certificateOne.certificate !== "none" && (
                                    <DashboardCard 
                                        title={
                                            <span className="flex items-center gap-2">
                                                <span>Your Certificate</span>
                                                {certificateOne.certificate === "valid" ? (
                                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400">
                                                        Valid
                                                    </span>
                                                ) : certificateOne.certificate === "failed" ? (
                                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-400">
                                                        Failed
                                                    </span>
                                                ) : certificateOne.certificate === "in-progress" && (
                                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-400">
                                                        In Progress
                                                    </span>
                                                )}
                                            </span>
                                        }
                                    >
                                        <ShowCertificateOrTest />
                                    </DashboardCard>
                                )}
                            </div>

                            {/* Right Column - Tickets */}
                            <DashboardCard 
                                title="Support Tickets"
                                action={
                                    <div className="flex items-center gap-2">
                                        <HideShowTickets />
                                        <CancelCreateTicket />
                                    </div>
                                }
                            >
                                <div className="space-y-4">
                                    {newTicketOpen ? (
                                        <NewTicketForm />
                                    ) : (
                                        <CurrentTicketSection />
                                    )}
                                </div>
                            </DashboardCard>
                        </div>
                    </div>
                </div>
            </AppLayout>
        ) : (
            <AppLayout header={<h2 className="font-semibold text-xl text-gray-800 leading-tight">Authenticating...</h2>} />
        )
    );
};

export default Dashboard;
