import { useEffect, useState } from "react";
import { NewPrompt } from "../types/NewPrompt";
import { Section } from "../types/Section";
import { Tool } from "../types/Tool";
import { PROMPT_SECTIONS } from "../constants/sections";

type PromptType = 'component' | 'example' | 'template';

export const AddPromptModal = ({ 
    isOpen, 
    onClose, 
    onSubmit,
    initialSection,
    tools,
    isLoadingTools
}: { 
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (prompt: NewPrompt) => void;
    initialSection: Section;
    tools: Tool[];
    isLoadingTools: boolean;
}) => {
    const [formData, setFormData] = useState<NewPrompt>({
        title: '',
        prompt: '',
        section: initialSection,
        labels: [],
        toolId: '',
        type: 'component' as PromptType
    });
    const [newLabel, setNewLabel] = useState('');
    const [isToolSectionOpen, setIsToolSectionOpen] = useState(false);
    const [isLabelSectionOpen, setIsLabelSectionOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setFormData(prev => ({
                ...prev,
                section: initialSection
            }));
        }
    }, [isOpen, initialSection]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
        setFormData({ title: '', prompt: '', section: initialSection, labels: [], toolId: '', type: 'component' });
        onClose();
    };

    const addLabel = () => {
        const trimmedLabel = newLabel.trim().toLowerCase();
        if (trimmedLabel && !formData.labels.includes(trimmedLabel)) {
            setFormData(prev => ({
                ...prev,
                labels: [...prev.labels, trimmedLabel]
            }));
            setNewLabel('');
        }
    };

    const handleLabelKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // If it's just a space/comma/enter with no actual content, ignore it
        if ((e.key === ' ' || e.key === ',' || e.key === 'Enter') && !newLabel.trim()) {
            e.preventDefault();
            return;
        }

        // If we have content and press a delimiter key, add the label
        if (e.key === ' ' || e.key === ',' || e.key === 'Enter') {
            e.preventDefault();
            addLabel();
        }
    };

    const handleLabelBlur = () => {
        addLabel();
    };

    if (!isOpen) return null;

    return (
        <div 
            className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-[60] p-4 sm:p-6"
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    onClose();
                }
            }}
        >
            <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl w-full max-w-lg relative z-[70]
                transform transition-all duration-300 scale-100 opacity-100">
                <div className="px-5 py-4 border-b border-gray-100 dark:border-gray-700">
                    <div className="flex justify-between items-center">
                        <div>
                            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Create New Prompt
                            </h2>
                            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                Add a new prompt to your library
                            </p>
                        </div>
                        <button 
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300
                                transition-colors duration-200"
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="p-5 space-y-4">
                    <div className="flex items-center space-x-3">
                        <label htmlFor="type" className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            Type
                        </label>
                        <div className="flex items-center gap-3">
                            <button
                                type="button"
                                onClick={() => setFormData(prev => ({ ...prev, type: 'component' }))}
                                className={`px-3 py-1.5 text-sm font-medium rounded-md transition-all duration-200
                                    ${formData.type === 'component'
                                        ? 'bg-blue-100 text-blue-700 dark:bg-blue-900/30 dark:text-blue-400'
                                        : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
                                    }`}
                            >
                                Component
                            </button>
                            <button
                                type="button"
                                onClick={() => setFormData(prev => ({ ...prev, type: 'example' }))}
                                className={`px-3 py-1.5 text-sm font-medium rounded-md transition-all duration-200
                                    ${formData.type === 'example'
                                        ? 'bg-blue-100 text-blue-700 dark:bg-blue-900/30 dark:text-blue-400'
                                        : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
                                    }`}
                            >
                                Example
                            </button>
                            <button
                                type="button"
                                onClick={() => setFormData(prev => ({ ...prev, type: 'template' }))}
                                className={`px-3 py-1.5 text-sm font-medium rounded-md transition-all duration-200
                                    ${formData.type === 'template'
                                        ? 'bg-blue-100 text-blue-700 dark:bg-blue-900/30 dark:text-blue-400'
                                        : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
                                    }`}
                            >
                                Template
                            </button>
                        </div>
                    </div>
                    <div className="space-y-3">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1.5">
                                Title
                            </label>
                            <input
                                type="text"
                                value={formData.title}
                                onChange={e => setFormData(prev => ({ ...prev, title: e.target.value }))}
                                className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700
                                    bg-white dark:bg-gray-800 text-gray-900 dark:text-white text-sm
                                    focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent
                                    transition-all duration-200"
                                placeholder="Enter prompt title..."
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1.5">
                                Prompt ({formData.type === 'component' ? 'Component' : formData.type === 'example' ? 'Example' : 'Template'})
                            </label>
                            <textarea
                                value={formData.prompt}
                                onChange={e => setFormData(prev => ({ ...prev, prompt: e.target.value }))}
                                className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700
                                    bg-white dark:bg-gray-800 text-gray-900 dark:text-white text-sm h-24
                                    focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent
                                    transition-all duration-200"
                                placeholder="Enter prompt content..."
                                required
                            />
                        </div>

                        <div className="pb-2">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                Section
                            </label>
                            <select
                                value={formData.section}
                                onChange={e => setFormData(prev => ({ ...prev, section: e.target.value as Section }))}
                                className="w-full px-4 py-2.5 rounded-lg border border-gray-200 dark:border-gray-700
                                    bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                            >
                                {PROMPT_SECTIONS.map(section => (
                                    <option key={section} value={section}>{section}</option>
                                ))}
                            </select>
                        </div>

                         {/* Labels Section */}
                         <div>
                            <button 
                                type="button"
                                onClick={() => setIsLabelSectionOpen(!isLabelSectionOpen)}
                                className="w-full flex justify-between items-center text-sm font-medium text-gray-600 dark:text-gray-300 
                                    hover:text-gray-800 dark:hover:text-gray-200 transition-colors"
                            >
                                <span>Labels</span>
                                <svg 
                                    className={`w-4 h-4 transition-transform duration-200 ${
                                        isLabelSectionOpen ? 'rotate-180' : ''
                                    }`}
                                    fill="none" 
                                    stroke="currentColor" 
                                    viewBox="0 0 24 24"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>
                            
                            <div 
                                className={`grid transition-all duration-300 ease-in-out 
                                    ${isLabelSectionOpen 
                                        ? 'grid-rows-[1fr] opacity-100 mt-4' 
                                        : 'grid-rows-[0fr] opacity-0 mt-0 overflow-hidden'}`}
                            >
                                <div className="overflow-hidden">
                                    <div className="flex justify-between items-center mb-2">
                                        {formData.labels.length > 0 && (
                                            <button
                                                type="button"
                                                onClick={() => setFormData(prev => ({
                                                    ...prev,
                                                    labels: []
                                                }))}
                                                className="text-xs text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 
                                                    transition-colors flex items-center gap-1"
                                            >
                                                <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                                                    />
                                                </svg>
                                                Clear All
                                            </button>
                                        )}
                                    </div>
                                    <div className="flex gap-2 mb-2">
                                        <input
                                            type="text"
                                            value={newLabel}
                                            onChange={e => setNewLabel(e.target.value)}
                                            onKeyDown={handleLabelKeyDown}
                                            onBlur={handleLabelBlur}
                                            className="flex-1 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700
                                                bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                                            placeholder="Type and press space, comma, or enter to add labels"
                                        />
                                    </div>
                                    <div className="flex flex-wrap gap-2">
                                        {formData.labels.map(label => (
                                            <span 
                                                key={label}
                                                className="inline-flex items-center px-2 py-1 text-xs font-medium rounded-full 
                                                    bg-gray-100 text-gray-600 dark:bg-gray-700 dark:text-gray-300"
                                            >
                                                <span>{label}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => setFormData(prev => ({
                                                        ...prev,
                                                        labels: prev.labels.filter(l => l !== label)
                                                    }))}
                                                    className="ml-1 w-4 h-4 flex items-center justify-center 
                                                        text-gray-400 hover:text-red-500 transition-colors"
                                                    aria-label="Remove label"
                                                >
                                                    ×
                                                </button>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Tool Section */}
                        <div className="mt-4 pb-3">
                            <button 
                                type="button"
                                onClick={() => setIsToolSectionOpen(!isToolSectionOpen)}
                                className="w-full flex justify-between items-center text-sm font-medium text-gray-600 dark:text-gray-300 
                                    hover:text-gray-800 dark:hover:text-gray-200 transition-colors"
                            >
                                <span>Additional Tool Details</span>
                                <svg 
                                    className={`w-4 h-4 transition-transform duration-200 ${
                                        isToolSectionOpen ? 'rotate-180' : ''
                                    }`}
                                    fill="none" 
                                    stroke="currentColor" 
                                    viewBox="0 0 24 24"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>
                            
                            <div 
                                className={`grid transition-all duration-300 ease-in-out 
                                    ${isToolSectionOpen 
                                        ? 'grid-rows-[1fr] opacity-100 mt-4' 
                                        : 'grid-rows-[0fr] opacity-0 mt-0 overflow-hidden'}`}
                            >
                                <div className="overflow-hidden">
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                                Tool
                                            </label>
                                            {isLoadingTools ? (
                                                <div className="animate-pulse h-10 bg-gray-200 dark:bg-gray-700 rounded-lg" />
                                            ) : (
                                                <select
                                                    value={formData.toolId}
                                                    onChange={e => setFormData(prev => ({ 
                                                        ...prev, 
                                                        toolId: e.target.value ? String(e.target.value) : '' 
                                                    }))}
                                                    className="w-full px-4 py-2.5 rounded-lg border border-gray-200 dark:border-gray-700
                                                        bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                                                >
                                                    <option value="">None</option>
                                                    {[...tools].sort((a, b) => a.name.localeCompare(b.name)).map(tool => (
                                                        <option key={tool.id} value={tool.id}>{tool.name}</option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="w-full px-4 py-2.5 rounded-lg bg-blue-600 text-white hover:bg-blue-700
                                transition-colors duration-200"
                        >
                            Add Prompt
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};