import React, { useEffect, useRef } from 'react';
import TicketStatusEnum from "../../enums/TicketStatusEnum";
import TicketUpdate from "./TicketUpdates";

const ReplyTicketModal = ({ showReplyModal, setShowReplyModal, ticketList, currentTicketId, replyText, setReplyText, handleReplySubmit, status }) => {
    const scrollRef = useRef(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [showReplyModal]);

    if (!showReplyModal) {
        return null;
    }

    const currentTicket = ticketList.find(ticket => ticket.id === currentTicketId);
    const ticketUpdates = currentTicket?.updates || [];

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm z-50">
            <div className="bg-white rounded-xl shadow-xl w-full max-w-2xl max-h-[83.333vh] flex flex-col animate-modal-appear">
                <div className="px-6 py-4 border-b border-gray-100 flex items-center justify-between">
                    <h2 className="text-xl font-semibold text-gray-900">Reply to Ticket</h2>
                    <button 
                        onClick={() => setShowReplyModal(false)}
                        className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                    >
                        <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div ref={scrollRef} className="overflow-y-auto flex-grow px-6 py-4 bg-gray-50">
                    <div className="flex flex-col-reverse space-y-reverse space-y-4">
                        {ticketUpdates.slice().reverse().map((update, index) => (
                            <TicketUpdate key={index} update={update} />
                        ))}
                    </div>
                </div>
                <div className="p-6 border-t border-gray-100 bg-white">
                    {status === TicketStatusEnum.Closed ? (
                        <div className="flex items-center gap-3 p-4 bg-red-50 rounded-lg mb-4">
                            <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p className="text-red-600 text-sm font-medium">
                                This ticket is closed. You cannot reply to it.
                            </p>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <textarea
                                value={replyText}
                                onChange={e => setReplyText(e.target.value)}
                                className="w-full h-32 p-4 text-gray-700 border border-gray-200 rounded-lg focus:ring-2 
                                    focus:ring-blue-500 focus:border-transparent transition-all duration-200 
                                    placeholder:text-gray-400 resize-none"
                                placeholder="Enter your reply here... If we can close the ticket, please let us know in your reply!"
                            />
                            <div className="flex justify-end gap-3">
                                <button 
                                    onClick={() => setShowReplyModal(false)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 
                                        bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
                                >
                                    Cancel
                                </button>
                                <button 
                                    onClick={handleReplySubmit}
                                    className="px-4 py-2 text-sm font-medium text-white rounded-lg
                                        transition-all duration-200 hover:-translate-y-0.5 shadow-sm hover:shadow
                                        bg-gradient-to-r from-[#468ef9] to-[#0c66ee] hover:from-[#3d7ed9] hover:to-[#0a56c7]"
                                >
                                    Submit Reply
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReplyTicketModal;