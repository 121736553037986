import React from 'react';
import { Section } from './types/Section';

interface NewPromptButtonProps {
    handleOpenModal: (section: Section) => void;
}

export const NewPromptButton: React.FC<NewPromptButtonProps> = ({ handleOpenModal }) => {
    return (
        <button
            onClick={() => handleOpenModal("Context")}
            className="flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium
                bg-blue-600 text-white hover:bg-blue-700 transition-colors"
        >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
            New Prompt
        </button>
    );
};

export default NewPromptButton;