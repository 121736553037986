import React from 'react';
import RoleEnum from '../enums/RoleEnum';

const RoleLabel = ({ role, className = '' }) => {
    const bgColor = role === RoleEnum.Registered ? 'bg-green-100 text-green-800' :
        role === RoleEnum.DefaultUser ? 'bg-sky-100 text-sky-800' :
        role === RoleEnum.Trainer ? 'bg-orange-100 text-orange-800' :
        role === RoleEnum.Moderator ? 'bg-purple-100 text-purple-800' :
        'bg-red-100 text-red-800'; // admin

    return (
        <span className={`px-3 py-1 rounded-full text-xs font-semibold ${bgColor} ${className}`}>
            {RoleEnum[role]}
        </span>
    );
};

export default RoleLabel; 