import React from "react";
import { ReactComponent as Logo } from "../../assets/img/logo/crafting-ai-logo.svg";
import NavLink from "../NavLink";
import {DropdownButton} from "../dashboard/DropdownLink";
import Dropdown from "../dashboard/Dropdown";
import {useNavigate} from 'react-router-dom';


const Navbar = ({...props}) => {
  const navigate = useNavigate();

  const MobileNavigation = () => {
        return(
            <span className={"lg:hidden"}>
              <NavLink name="Introduction" url="/documentation/large-language-models" />

              <NavLink name="Prompt Engineering" url="/documentation/prompt-engineering#prompt-engineering" />

              <NavLink name="Framework Introduction" url="/documentation/framework/introduction" />

              <NavLink name="Framework" url="/documentation/framework#documentation" />
              <div className={"pl-7"}>
                <NavLink name="Crafting Phase" url="/documentation/framework#craft" />
                <NavLink name="Validation Phase" url="/documentation/framework#validate" />
                <NavLink name="Enhancement Phase" url="/documentation/framework#enhance" />
                <NavLink name="Cheatsheet" url="/documentation/framework/cheatsheet" />
              </div>

              <NavLink name="Framework IVPE" url="/documentation/framework/visual#documentation" />
              <div className={"pl-7"}>
                <NavLink name="Crafting Phase" url="/documentation/framework/visual#craft" />
                <NavLink name="Validation Phase" url="/documentation/framework/visual#validate" />
                <NavLink name="Enhancement Phase" url="/documentation/framework/visual#enhance" />
              </div>

              <NavLink name="Prompt Techniques" url="/documentation/prompt-techniques#prompt-techniques" />
              <div className={"pl-7"}>
                <NavLink name="Shot prompting" url="/documentation/prompt-techniques#zero-single-few-shot" />
                <NavLink name="Chain of Thought (CoT) prompting" url="/documentation/prompt-techniques#chain-of-thought-prompting" />
                <NavLink name="Emotional prompting" url="/documentation/prompt-techniques#emotional-prompting" />
              </div>

              <NavLink name="Adversarial Prompting" url="/documentation/adversarial-prompting#adversarial-prompting" />
              <div className={"pl-7"}>
                <NavLink name="Prompt Injections" url="/documentation/adversarial-prompting#prompt-injections" />
                <NavLink name="Prompt Leaking" url="/documentation/adversarial-prompting#prompt-leaking" />
                <NavLink name="Prompt Jailbreaking" url="/documentation/adversarial-prompting#prompt-jailbreaking" />
                <NavLink name="Risk and impact score" url="/documentation/adversarial-prompting#risk-impact-score" />
                <NavLink name="Adversarial prompts overview" url="/documentation/adversarial-prompting#prompt-injection-overview" />
              </div>
              <div className={"pl-7"}>
                <NavLink name="Prompt Injections" url="/documentation/adversarial-prompting/prompt-injections#prompt-injections" />
                <div className={"pl-7"}>
                  <NavLink name="Memory Manipulation" url="/documentation/adversarial-prompting/prompt-injections#memory-injection" />
                  <NavLink name="Website" url="/documentation/adversarial-prompting/prompt-injections#website-injection" />
                  <NavLink name="Image/Document" url="/documentation/adversarial-prompting/prompt-injections#image-injection" />
                </div>
                <NavLink name="Prompt Leaking" url="/documentation/adversarial-prompting/prompt-leaking#prompt-leaking" />
                <div className={"pl-7"}>
                  <NavLink name="Custom GPT" url="/documentation/adversarial-prompting/prompt-leaking#custom-gpt-leaking" />
                </div>
                <NavLink name="Prompt Jailbreaking" url="/documentation/adversarial-prompting/prompt-jailbreak#prompt-jailbreak" />
                <div className={"pl-7"}>
                  <NavLink name="ASCII Art-based" url="/documentation/adversarial-prompting/prompt-jailbreak#ASCII-Art-based-jailbreak" />
                  <NavLink name="Many-shot" url="/documentation/adversarial-prompting/prompt-jailbreak#many-shot-jailbreak" />
                </div>
              </div>

              <NavLink name="Prompt Architecture" url="/documentation/prompt-architecture#prompt-architecture" />
              <div className={"pl-7"}>
                <NavLink name="Prompt Libraries" url="/documentation/prompt-architecture#prompt-libraries-overview" />
                <NavLink name="Software Engineering perspective" url="/documentation/prompt-architecture#software-engineering-perspective" />
                <NavLink name="Prompt component library" url="/documentation/prompt-architecture#prompt-component-library" />
                <NavLink name="Prompt recipes" url="/documentation/prompt-architecture#prompt-recipes" />
                <NavLink name="Workflow prompting" url="/documentation/prompt-architecture#prompt-workflows" />
                <NavLink name="Framework integration" url="/documentation/prompt-architecture#framework-integration" />
              </div>

                {userIsLoggedIn() &&
                    <>
                        <NavLink name="Tools" url="/documentation/tools#tools" />
                    </>}

            </span>
        );
  }

  const DesktopNavigation = () => {
    return(<><span className={"hidden lg:block"}>
                <NavLink name="Framework" url="/documentation/framework/introduction" />
              </span>
            <span className={"hidden lg:block"}>
              <NavLink name="Documentation" url="/documentation/large-language-models#llms" />
            </span>
          </>);
  }

  const userIsLoggedIn = () => {
      return !(localStorage.getItem("username") === "" || localStorage.getItem("username") === undefined || localStorage.getItem("username")  === null);
  }

  return (
      <nav id="navbar" className={`relative z-10 w-full text-neutral-800 dark:bg-slate-900 dark:text-white`}>
        <div className="flex flex-col max-w-screen-xl px-8 mx-auto lg:items-center lg:justify-between lg:flex-row py-4">
          <div className="flex flex-col lg:flex-row items-center space-x-4 xl:space-x-8">
            <div className="w-full flex flex-row items-center justify-between py-6">
              <div>
                <a href={"/"} title="Crafting AI Prompts Framework"><Logo /></a>
              </div>
                <button
                    className="rounded-lg lg:hidden focus:outline-none focus:shadow-outline relative w-6 h-6"
                    onClick={() => { props.toggleSidebar(!props.open); }}
                >
                    <span className={`absolute block w-full h-0.5 bg-current transform transition-all duration-300 ease-in-out ${props.open ? 'rotate-45 ' : '-translate-y-2'}`}></span>
                    <span className={`absolute block w-full h-0.5 bg-current transform transition-all duration-300 ease-in-out ${props.open ? 'opacity-0' : 'opacity-100'}`}></span>
                    <span className={`absolute block w-full h-0.5 bg-current transform transition-all duration-300 ease-in-out ${props.open ? '-rotate-45' : 'translate-y-2'}`}></span>
                </button>
            </div>
            <div className={"w-full h-auto flex flex-col flex-grow lg:items-center relative"}>
              <ul
                className={`${
                  props.open ? "flex" : "hidden lg:flex"
                } w-full h-auto flex flex-col flex-grow lg:items-center pb-4 lg:pb-0 lg:justify-end lg:flex-row origin-top duration-300 xl:space-x-2 space-y-3 lg:space-y-0`}
              >
                <NavLink name="Home" url="/" />

                <MobileNavigation />

                <DesktopNavigation />

                <NavLink name="Certification" url="/test" />

                <NavLink name="Blogs" url="/blogs" />

                <span className={"lg:hidden pb-3 block"}>
                    {!userIsLoggedIn() ?
                        <NavLink name="Login" url="/login" />
                        : <span className={"py-3 block"}>
                            <NavLink name={localStorage.getItem("username") + ""} url="/dashboard/profile" />
                            <NavLink name="Dashboard" url="/dashboard/overview" />

                            <li className="w-full">
                              <button
                                  className="md:px-4 font-sans py-2 text-sm bg-transparent rounded-lg text-[#666666] dark:text-white hover:text-gray-900 hover:dark:text-gray-400"
                                  onClick={() => navigate('/logout')}
                              >
                                Logout
                              </button>
                            </li>
                          </span>
                    }
                </span>

                <li className={"w-full"}>
                  <button onClick={props.toggleDarkMode} id="theme-toggle"
                     className="cursor-pointer lg:hidden md:px-4 font-sans text-sm bg-transparent rounded-lg text-[#666666] dark:text-white hover:text-gray-900 hover:dark:text-gray-400"
                  >
                    <span id="theme-toggle-dark-icon-mob" className="z w-5 h-5">Dark mode</span>
                    <span id="theme-toggle-light-icon-mob" className="hidden w-5 h-5">Light mode</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className={"hidden lg:flex"}>
            {/* Settings Dropdown */}
            {
                !userIsLoggedIn() ?
                  <button onClick={() => navigate("/login")} className="flex items-center text-sm font-medium
             text-gray-500 hover:text-gray-700 focus:outline-none
             transition duration-150 ease-in-out mr-5">Login</button>
                  :
                  <div className="hidden sm:flex sm:items-center sm:ml-6 mr-5">
                    <Dropdown
                        align="right"
                        trigger={
                          <button className="flex items-center text-sm font-medium
             text-gray-500 hover:text-gray-700 focus:outline-none
             transition duration-150 ease-in-out">
                            <div>{localStorage.getItem("username")}</div>
                            <div className="ml-1">
                              <svg
                                  className="fill-current h-4 w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20">
                                <path
                                    fillRule="evenodd"
                                    // eslint-disable-next-line max-len
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          </button>
                        }>
                      {/* Authentication */}
                      <DropdownButton onClick={() => navigate("/dashboard/overview")}>
                        Dashboard
                      </DropdownButton>
                      <DropdownButton onClick={() => navigate("/dashboard/profile")}>
                        Profile
                      </DropdownButton>
                      <DropdownButton onClick={() => navigate('/logout')}>
                        Logout
                      </DropdownButton>
                    </Dropdown>
                  </div>
            }
            {/* ENABLE IF DARK/LIGHT MODE SWITCH SHOULD BE IN */}
            <button onClick={props.toggleDarkMode} id="theme-toggle" type="button"
                    className="hidden lg:flex text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
              <svg id="theme-toggle-dark-icon" className="z w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
              </svg>
              <svg id="theme-toggle-light-icon" className="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>
  );
};

export default Navbar;
