import { Section } from '../types/Section';

export const PROMPT_SECTIONS: readonly Section[] = [
    "Context",
    "Register",
    "Acting Role",
    "Format",
    "Task"
] as const;

export const PROMPT_SECTIONS_MERGED_PROMPTS: readonly Section[] = [
    "Register",
    "Acting Role",
    "Format",
    "Task",
    "Context"
] as const;

// If you need to include Recipes section in some places
export const ALL_SECTIONS: readonly Section[] = [
    ...PROMPT_SECTIONS,
    "Recipes"
] as const;