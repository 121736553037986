import React from "react";
import {NavLink} from "react-router-dom";
import SubNavButton from "../../components/base/SubNavButton";
import SectionIDEnum, {NAVIGATE_TO_SECTION} from "../../components/enums/SectionIDEnum";
import LinksEnum from "../../components/enums/LinksEnum";
import PageEnum from "../../components/enums/PageEnum";
import BorderSubBottom from "../../components/border/BorderSubBottom";
import BorderBottom from "../../components/border/BorderBottom";

interface DocumentationNavProps {
    page: string
}

interface TitleNavLinkProps {
    title: string, // Title visible in menu
    url: string, // Redirect to which page
    open: boolean, // Is the menu item open/closed
    subNav?: boolean, // Different color menu button
    mainPage?: boolean, // To make it also highlight in navbar if there is no Hash used.
    noSub?: boolean // No subnav
}

const DocumentationNav: React.FC<DocumentationNavProps> = ({ page }) => {
        const titleSizeClass = " text-[1.1rem] ";
        const documentationPreUrl = LinksEnum.Documentation;

        const userIsLoggedIn = () => {
            return !(localStorage.getItem("username") === "" || localStorage.getItem("username") === undefined || localStorage.getItem("username")  === null);
        }

        const craftingPhaseSub = [
            {
                anchor: NAVIGATE_TO_SECTION(SectionIDEnum.FrameworkCraftC),
                title: "C - Context",
            },
            {
                anchor: NAVIGATE_TO_SECTION(SectionIDEnum.FrameworkCraftR),
                title: "R - Register"
            },
            {
                anchor: NAVIGATE_TO_SECTION(SectionIDEnum.FrameworkCraftA),
                title: "A - Acting Role"
            },
            {
                anchor: NAVIGATE_TO_SECTION(SectionIDEnum.FrameworkCraftF),
                title: "F - Format",
            },
            {
                anchor: NAVIGATE_TO_SECTION(SectionIDEnum.FrameworkCraftT),
                title: "T - Task",
            }];

        const validationPhaseSub = [{
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.FrameworkValidateI),
            title: "I - Interactive",
        },
            {
                anchor: NAVIGATE_TO_SECTION(SectionIDEnum.FrameworkValidateN),
                title: "N - Non-Disclosure"
            },
            {
                anchor: NAVIGATE_TO_SECTION(SectionIDEnum.FrameworkValidateG),
                title: "G - Goal-Driven"
            }];

        const enhancementPhaseSub = [
            {
                anchor: NAVIGATE_TO_SECTION(SectionIDEnum.FrameworkEnhanceAI),
                title: "AI - Adapt & Improve",
            }
        ];

    const shotPromptingSub = [
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.TechniquesShotZero),
            title: "Zero Shot",
        },
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.TechniquesShotSingle),
            title: "Single Shot"
        },
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.TechniquesShotFew),
            title: "Few Shot"
        },
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.TechniquesShotWhyImportant),
            title: "Why important",
        }];

    const websiteInjectionSub = [
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionWebsiteRobotTxt),
            title: "Robot.txt",
        },
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionWebsiteTheInjection),
            title: "The injection",
        },{
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionWebsiteAssessment),
            title: "Risk Assessment",
        },
    ];

    const imageInjectionSub = [
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionImageIntroduction),
            title: "Image injection",
        },{
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionImageDocument),
            title: "Document injection",
        },{
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionImageAssessment),
            title: "Risk Assessment",
        },
    ];

    const customGPTInjectionSub = [
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionCustomGPTPros),
            title: "Pro's",
        },{
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionCustomGPTInjection),
            title: "The injection",
        },{
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionCustomGPTAssessment),
            title: "Risk Assessment",
        },
    ];

    const asciiInjection = [
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionArtPrompt),
            title: "ArtPrompt Injection",
        },
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionArtPromptExample),
            title: "Example",
        },
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionArtPromptAssessment),
            title: "Risk Assessment",
        },
        {
            anchor: NAVIGATE_TO_SECTION(SectionIDEnum.InjectionArtPromptResearchPapers),
            title: "Research papers",
        },
    ];

    const ArrowDownIcon = () => {
        return(<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-8 pl-2 w-7"
        >
            <path
                fillRule="evenodd"
                d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                clipRule="evenodd"
            />
        </svg>);
    }

    const TitleNavLink: React.FC<TitleNavLinkProps> = ({title, url, open, subNav, mainPage, noSub}) => {
        const isOnPage: boolean = window.location.pathname.includes(url) || open || mainPage === true;

        if (open) {
            return (
                <NavLink to={url} className={"flex rounded-lg mt-3 mb-3 py-2 " + (isOnPage ? "bg-[#468ef9] dark:bg-slate-950" : "bg-slate-800")}>
                    { !subNav ?
                        <span className={" ml-4 text-2xl font-bold" + (isOnPage ? " text-white dark:text-sky-500 " : "text-sky-500") + titleSizeClass}>{title}</span> :
                        <span className={"ml-4 text-2xl font-bold" + (isOnPage ? " text-white dark:text-sky-300 " : "text-sky-300 ") + titleSizeClass}>{title}</span> }
                </NavLink>
            );
        }else {
            return (
                <>
                    <NavLink to={url} className={"flex hover:dark:bg-slate-950 hover:bg-[#468ef9] rounded-lg hover:text-white py-2 px-3" }>
                        {!subNav ?
                            <span className={"flex-grow hover:text-white text-sky-500 pt-1 pl-1 text-[1.1rem] font-bold" + titleSizeClass}>{title}</span> :
                            <span className={"flex-grow hover:text-white text-sky-700 pt-1 pl-1 text-[1.1rem] font-bold" + titleSizeClass}>{title}</span>
                        }
                        {!noSub ? <ArrowDownIcon/> : <></>}
                    </NavLink>
                    { !subNav ? <BorderBottom /> : <></> }
                </>
            );
        }
    }

    const PromptFrameworks = () => {
        const navLink = LinksEnum.Framework;
        if(page === PageEnum.Framework || page === PageEnum.VisualFramework || page === PageEnum.FrameworkIntroduction) {
            return(
                <>
                    <TitleNavLink title={"Crafting AI Prompts"} url={navLink + "/introduction#introduction"} open={true} />
                    <SubNavButton anchorRef={navLink + "/introduction#introduction"}  title="Framework introduction" isMainPage={true} />

                    <div className={"mb-2"}>
                        <FrameworkDocumentationSubNav />
                    </div>

                    <hr className="w-[60%] h-[0.3px] ml-4 bg-gray-600 border-0 mb-2" />

                    <VisualFrameworkDocumentationSubNav />

                    <BorderSubBottom />
                </>
            );
        }
        return(<TitleNavLink title={"Crafting AI Prompts"} url={navLink + "/introduction#introduction"} open={false} subNav={false} />);
    }

    const FrameworkDocumentationSubNav = () => {
        const navLink = documentationPreUrl + '/framework';
        if (page === "Framework") {
            return(
                <>
                    <TitleNavLink title={"Framework"} url={navLink + "#documentation"} open={true} subNav={true} />
                    <SubNavButton anchorRef={navLink + "#documentation"}  title="Phases" />
                    <SubNavButton anchorRef={navLink + "#craft"}      title="Crafting phase"      innerNav={craftingPhaseSub} />
                    <SubNavButton anchorRef={navLink + "#validate"}   title="Validation phase"    innerNav={validationPhaseSub} />
                    <SubNavButton anchorRef={navLink + "#enhance"}    title="Enhancement phase"   innerNav={enhancementPhaseSub} />
                    <SubNavButton anchorRef={navLink + "/cheatsheet#cheatsheet"}  title="Cheatsheet" isMainPage={true} />
                </>
            );
        }
        return(<TitleNavLink title={"Framework"} url={navLink + "#documentation"} open={false} subNav={true} />);
    }

    const VisualFrameworkDocumentationSubNav = () => {
        const navLink = documentationPreUrl + '/framework/visual';
        if (page === "VisualFramework") {
            return(
                <>
                    <TitleNavLink title={"IVPE Framework"} url={navLink + "#documentation"} open={true} subNav={true} />
                    <SubNavButton anchorRef={navLink + "#documentation"}  title="Phases"/>
                    <SubNavButton anchorRef={navLink + "#craft"}      title="Crafting phase"      innerNav={craftingPhaseSub} />
                    <SubNavButton anchorRef={navLink + "#validate"}   title="Validation phase"    innerNav={validationPhaseSub} />
                    <SubNavButton anchorRef={navLink + "#enhance"}    title="Enhancement phase"   innerNav={enhancementPhaseSub} />
                </>
            );
        }
        return(<TitleNavLink title={"IVPE Framework"} url={navLink + "#documentation"} open={false} subNav={true} />);
    }

    const PromptTechniquesNav = () => {
        const navLink = documentationPreUrl + '/prompt-techniques';
        if(page === "PromptTechniques") {
            return(
                <>
                    <TitleNavLink title={"Prompt Techniques"} url={navLink} open={true} />
                    <SubNavButton anchorRef={navLink + "#prompt-techniques"} title="Introduction" isMainPage={true} />
                    <SubNavButton anchorRef={navLink + "#zero-single-few-shot"} title="Shot prompting" innerNav={shotPromptingSub} />
                    <SubNavButton anchorRef={navLink + "#chain-of-thought-prompting"} title="Chain of Thought (CoT) prompting" />
                    <SubNavButton anchorRef={navLink + "#emotional-prompting"} title="Emotional prompting" />
                    <BorderBottom />
                </>
            );
        }else if(sessionStorage.getItem('handout-framework') === "true") {
            // Do not show due to handout
            return(<></>);
        }
        return(<TitleNavLink title={"Prompt Techniques"} url={navLink + "#prompt-techniques"} open={false} />);
    }

    const PromptAdversarialNav = () => {
        const navLink = documentationPreUrl + '/adversarial-prompting';
        if(page === "AdversarialPrompting" || page === "PromptInjections" || page === "PromptLeaking" || page === "PromptJailbreak") {
            return(
                <>
                    <TitleNavLink title={"Adversarial Prompting"} url={navLink + "#adversarial-prompting"} open={true} />
                    <SubNavButton anchorRef={navLink + "#prompt-injections"} title="Prompt Injections" />
                    <SubNavButton anchorRef={navLink + "#prompt-leaking"} title="Prompt Leaking" />
                    <SubNavButton anchorRef={navLink + "#prompt-jailbreaking"} title="Prompt Jailbreaking" />
                    <SubNavButton anchorRef={navLink + "#risk-impact-score"} title="Risk and impact score" />
                    <SubNavButton anchorRef={navLink + "#prompt-injection-overview"} title="Adversarial prompts Overview" />

                    <hr className="w-[60%] h-[0.3px] ml-4 bg-gray-600 border-0 mb-2 mt-1" />

                    <PromptInjectionsNav />

                    <hr className="w-[60%] h-[0.3px] ml-4 bg-gray-600 border-0 mb-2 mt-1" />

                    <PromptLeakingNav />

                    <hr className="w-[60%] h-[0.3px] ml-4 bg-gray-600 border-0 mb-2 mt-1" />

                    <PromptJailbreakNav />

                    <BorderBottom />
                </>
            );
        }
        return(<TitleNavLink title={"Adversarial Prompting"} url={navLink + "#adversarial-prompting"} open={false} />);
    }

    const PromptInjectionsNav = () => {
        const navLink = documentationPreUrl + '/adversarial-prompting/prompt-injections';
        if(page === "PromptInjections") {
            return(
                <>
                    <TitleNavLink title={"Prompt Injections"} url={navLink + "#injections"} open={true} subNav={true} />
                    <SubNavButton anchorRef={navLink + "#memory-injection"} title="Memory Manipulation" />
                    <SubNavButton anchorRef={navLink + "#website-injection"} title="Website Injections" innerNav={websiteInjectionSub} />
                    <SubNavButton anchorRef={navLink + "#image-injection"} title="Image/Document Injections" innerNav={imageInjectionSub} />
                </>
            );
        }
        return(<TitleNavLink title={"Prompt Injections"} url={navLink + "#prompt-injections"} open={false} subNav={true} />);
    }

    const PromptLeakingNav = () => {
        const navLink = documentationPreUrl + '/adversarial-prompting/prompt-leaking';
        if(page === "PromptLeaking") {
            return(
                <>
                    <TitleNavLink title={"Prompt Leaking"} url={navLink + "#leaking"} open={true} subNav={true} />
                    <SubNavButton anchorRef={navLink + "#custom-gpt-leaking"} title="Custom GPT Leaking" innerNav={customGPTInjectionSub} />

                </>
            );
        }
        return(<TitleNavLink title={"Prompt Leaking"} url={navLink + "#prompt-leaking"} open={false} subNav={true} />);
    }

    const PromptJailbreakNav = () => {
        const navLink = documentationPreUrl + '/adversarial-prompting/prompt-jailbreak';
        if(page === "PromptJailbreak") {
            return(
                <>
                    <TitleNavLink title={"Prompt Jailbreak"} url={navLink + "#injections"} open={true} subNav={true} />
                    <SubNavButton anchorRef={navLink + "#ASCII-Art-based-jailbreak"} title="ASCII Art Jailbreak" innerNav={asciiInjection} />
                    <SubNavButton anchorRef={navLink + "#many-shot-jailbreak"} title="Many-shot Jailbreak" />

                </>
            );
        }
        return(<TitleNavLink title={"Prompt Jailbreak"} url={navLink + "#prompt-jailbreak"} open={false} subNav={true} />);
    }

    const PromptEngineeringNav = () => {
        const navLink = documentationPreUrl + '/prompt-engineering';
        if(page === "PromptEngineering") {
            return(
                <>
                    <TitleNavLink title={"Prompt Engineering"} url={navLink + "#prompt-engineering"} open={true} />
                    <SubNavButton anchorRef={navLink + "#prompt-engineering"} title="Prompt Engineering (PE)" isMainPage={true} />
                    <SubNavButton anchorRef={navLink + "#interactive-prompt-engineering"} title="Interactive PE (IPE)" />
                    <SubNavButton anchorRef={navLink + "#interactive-visual-prompt-engineering"} title="Interactive Visual PE (IVPE)" />
                    <SubNavButton anchorRef={navLink + "#system-prompt-engineering"} title="System PE (SPE)" />
                    <BorderBottom />
                </>
            );
        }
        return(<TitleNavLink title={"Prompt Engineering"} url={navLink + "#prompt-engineering"} open={false} />);
    }

    const LargeLanguageModelsNav = () => {
        const navLink = documentationPreUrl + '/large-language-models';
        if(page === "LargeLanguageModels") {
            return(
                <>
                    <TitleNavLink title={"Introduction"} url={navLink + "#llms"} open={true} />
                    <SubNavButton anchorRef={navLink + "#what-is-an-llm"} title="What is an LLM?" />
                    <SubNavButton anchorRef={navLink + "#prediction-based"} title="Prediction based" />
                    <SubNavButton anchorRef={navLink + "#token-based"} title="Token based" />
                    <SubNavButton anchorRef={navLink + "#context-window"} title="Context Window" />
                    <SubNavButton anchorRef={navLink + "#diffusion-models"} title="What are Diffusion Models?" />
                    <BorderBottom />
                </>
            );
        }
        return(<TitleNavLink title={"Introduction"} url={navLink + "#llms"} open={false} />);
    }

    const PromptArchitecture = () => {
        const navLink = documentationPreUrl + '/prompt-architecture';
        if(page === "PromptArchitecture") {
            return(
                <>
                    <TitleNavLink title={"Prompt Architecture"} url={navLink + "#prompt-architecture"} open={true} />
                    <SubNavButton anchorRef={navLink + "#prompt-libraries-overview"} title="Prompt Libraries" />
                    <SubNavButton anchorRef={navLink + "#software-engineering-perspective"} title="Software Engineering perspective" />
                    <SubNavButton anchorRef={navLink + "#prompt-component-library"} title="Prompt component library" />
                    <SubNavButton anchorRef={navLink + "#prompt-recipes"} title="Prompt recipes" />
                    <SubNavButton anchorRef={navLink + "#prompt-workflows"} title="Workflow prompting" />
                    <SubNavButton anchorRef={navLink + "#framework-integration"} title="Framework integration" />
                    <BorderBottom />
                </>
            );
        }
        return(<TitleNavLink title={"Prompt Architecture"} url={navLink + "#prompt-architecture"} open={false} />);
    }

    const Tools = () => {
        const navLink = documentationPreUrl + '/tools';
        if(page === "Tools") {
            return(
                <>
                    <TitleNavLink title={"Tools"} url={navLink + "#tools"} open={true} />
                    <BorderBottom />
                </>
            );
        }
        return(<TitleNavLink title={"Tools"} url={navLink + "#tools"} open={false} noSub={true} />);
    }


    return (
        <div className="col-span-12 lg:col-span-3 hidden lg:block sticky top-8  z-50"
                 style={{"maxHeight": "95vh", "overflow": "auto", "marginTop": "50px"}}>
            <nav className="mt-10 px-2">
                <LargeLanguageModelsNav />

                <PromptEngineeringNav />

                <PromptFrameworks />

                <PromptTechniquesNav />

                <PromptAdversarialNav />

                <PromptArchitecture />

                {
                    userIsLoggedIn() ? <Tools /> :
                    <>
                        <div className="opacity-50 cursor-not-allowed">
                            <div className="text-xs text-gray-500 ml-1 dark:text-gray-400 pl-3 py-1">
                                Requires login
                            </div>
                            <TitleNavLink title={"Tools"} url="/login" open={false} noSub={true} />
                        </div>
                    </>
                }

                {/*<div className={"px-3 py-2 flex-grow text-sky-500 font-bold" + titleSizeClass}>Coming soon...</div>*/}
                {/*<SubNavButton title="Prompt Architecture" disabled={true}/>*/}
            </nav>
        </div>);

}

export default DocumentationNav;