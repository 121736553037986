import React from 'react';
import { Tool } from './types/Tool';

interface ToolBadgeProps {
    toolId: string | number;
    tools: Tool[];
}

export const ToolBadge: React.FC<ToolBadgeProps> = ({ toolId, tools }) => {
    // Convert toolId to number for comparison
    const numericToolId = Number(toolId);
    const tool = tools.find(t => Number(t.id) === numericToolId);
    
    if (!tool || !tools.length) return null;
    
    return (
        <div className="flex flex-wrap gap-1.5">
            <span className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium
                bg-purple-50 text-purple-600 dark:bg-purple-900/20 dark:text-purple-400"
            >
                <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" 
                    />
                </svg>
                {tool.name}
            </span>
        </div>
    );
};