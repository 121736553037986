import { PromptLibraryPrompt } from '../types/PromptLibraryPrompt';

interface FilterPromptsProps {
    prompts: PromptLibraryPrompt[];
    searchQuery: string;
    selectedTools: string[];
    selectedLabels: string[];
}

export const filterPrompts = ({ 
    prompts,
    searchQuery,
    selectedTools,
    selectedLabels 
}: FilterPromptsProps): PromptLibraryPrompt[] => {
    return prompts.filter(prompt => {
        const matchesSearch = prompt.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            prompt.prompt.toLowerCase().includes(searchQuery.toLowerCase());
            
        const matchesTools = selectedTools.length === 0 || 
            selectedTools.includes(String(prompt.toolId)); // Convert to string for comparison
            
        const matchesLabels = selectedLabels.length === 0 || 
            prompt.labels.some(label => selectedLabels.includes(label));
            
        return matchesSearch && matchesTools && matchesLabels;
    });
};