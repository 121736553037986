import { Section } from "./types/Section";

interface NoPromptFoundProps {
    section?: Section;
    handleOpenModal: (section: Section) => void;
}

export const NoPromptFound = ({ section, handleOpenModal }: NoPromptFoundProps) => {
    return(
        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4
            border border-dashed border-gray-200 dark:border-gray-700">
            <div className="flex items-center gap-4">
                <div className="rounded-full bg-gray-100 dark:bg-gray-700 p-2 flex-shrink-0">
                    <svg 
                        className="w-4 h-4 text-gray-400 dark:text-gray-500" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                    >
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                    </svg>
                </div>
                <div>
                    <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                        No prompts {section && 'in ' + section.toLowerCase()} yet
                    </h3>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                        {section === "Context" && "Add prompts that set the context or background for your AI interactions."}
                        {section === "Register" && "Add prompts that define the tone, style, or language register to be used."}
                        {section === "Acting Role" && "Add prompts that specify the role or persona the AI should adopt."}
                        {section === "Format" && "Add prompts that define the structure or format of the AI's response."}
                        {section === "Task" && "Add prompts that specify the actual task or request for the AI."}
                    </p>
                    <button
                        onClick={() => handleOpenModal(section || "Context")}
                        className="inline-flex items-center gap-1 mt-2 text-xs font-medium
                            text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                    >
                        <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                        Add your first {section && section.toLowerCase()} prompt
                    </button>
                </div>
            </div>
        </div>
    );
}