import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect, useRef, useState} from "react";
import RoleEnum from "../../components/enums/RoleEnum";
import axios from "../../libs/axios";
import {
    CheckCircle,
    XCircle,
    Loader,
    ArrowUpCircle,
    ArrowDownCircle,
    Ban,
    Unlock,
    Award,
    Users, MessageCircle, Edit
} from 'lucide-react';
import {useNavigate} from "react-router-dom";
import {Button} from "@headlessui/react";
import TicketStatusEnum from "../../components/enums/TicketStatusEnum";
import BaseButton from "../../components/base/Button";
import ReplyTicketModal from "../../components/dashboard/dashboard/ReplyTicketModal";
import Pagination from "../../components/dashboard/dashboard/Pagination";
import RoleLabel from '../../components/dashboard/RoleLabel';
import StatusLabel from '../../components/dashboard/StatusLabel';
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";

const Admin = () => {
    const { user } = useAuth({ middleware: 'admin' })
    const [userList, setUserList] = useState([]);
    const [ticketList, setTicketList] = useState([]);
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [currentTicketId, setCurrentTicketId] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [ticketCurrentPage, setTicketCurrentPage] = useState(1);
    const ticketsPerPage = 10;
    const [sortField, setSortField] = useState('id');
    const [sortDirection, setSortDirection] = useState('desc');
    const [ticketSortField, setTicketSortField] = useState('id');
    const [ticketSortDirection, setTicketSortDirection] = useState('desc');
    const [showClosedTickets, setShowClosedTickets] = useState(false);

    const apiCallMadeRef = useRef(false);
    let navigate = useNavigate();

    const { csrf } = useAuth();

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Admin"

        // Get userList from /api/users and store in setUserList
        const fetchData = async () => {
            if (apiCallMadeRef.current) return;
            apiCallMadeRef.current = true;

            try {
                await csrf();
                const response = await axios.get("/admin/get-user-list");
                const sortedUsers = response.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
                setUserList(sortedUsers);
                if (response.status !== 401) {
                    const ticketResponse = await axios.get("/admin/get-ticket-list");
                    const sortedTickets = ticketResponse.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
                    setTicketList(sortedTickets);
                }
            } catch (error) {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    navigate('/dashboard/overview');
                    window.location.reload();
                }
            }
        };

        fetchData();
    }, []);

    async function upgradeAccountToTakeTests(userID, roleID) {
        // Write for me a post to /admin/upgrade-user-role-1/$userID
        try {
            await csrf();
            const response = await axios.post(`/admin/upgrade-user-role/${userID}/${roleID}`);
            setUserList(userList.map(user => user.id === userID ? response.data : user));
        } catch (error) {
            //console.error(error);
        }
    }

    async function banAccount(userID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/ban-user/${userID}`);
            setUserList(userList.map(user => user.id === userID ? response.data : user));
        } catch (error) {
            //console.error(error);
        }
    }

    async function unBanAccount(userID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/unban-user/${userID}`);
            setUserList(userList.map(user => user.id === userID ? response.data : user));
        } catch (error) {
            //console.error(error);
        }
    }

    async function closeTicket(ticketId) {
        await csrf();
        axios.post(`/admin/close-ticket/${ticketId}`).then(() => {
            setTicketList(ticketList.map(ticket => ticket.id === ticketId ? {...ticket, status: 2} : ticket));
        }).catch((error) => {

        });
    }

    function replyToTicket(ticketId) {
        setCurrentTicketId(ticketId);
        setShowReplyModal(true);
    }

    async function handleReplySubmit() {
        try {
            await csrf();
            axios.post(`/ticket/reply`, {
                ticket_id: currentTicketId,
                message: replyText
            }).then(response => {
                // Update the ticket list
                setTicketList(ticketList.map(ticket => ticket.id === currentTicketId ? {
                    ...ticket,
                    updates: [...ticket.updates, {
                        user: user,
                        description: replyText,
                        updated_at: new Date().toISOString()
                    }],
                    status: response.data.new_ticket_status // update the status of the ticket
                } : ticket));
            });
            setShowReplyModal(false);
            setReplyText('');
        } catch (error) {
            // Do nothing
        }
    }

    const totalCertificates = userList.reduce((total, user) => total + (user.tests?.filter(test => test.certificate_id !== null).length || 0), 0);    const bannedUsersCount = userList.filter(user => user.banned_user).length;

    // First sort the complete userList
    const sortedUsers = [...(userList || [])].sort((a, b) => {
        // Always convert IDs to numbers first
        const aId = Number(a.id);
        const bId = Number(b.id);
        
        if (sortField === 'id') {
            // Simple numeric comparison
            return sortDirection === 'asc' ? aId - bId : bId - aId;
        }
        
        // For other fields
        if (sortField === 'tests') {
            const aTests = a.tests?.length || 0;
            const bTests = b.tests?.length || 0;
            return sortDirection === 'asc' ? aTests - bTests : bTests - aTests;
        }

        const aValue = a[sortField] || '';
        const bValue = b[sortField] || '';
        
        // String comparison for non-numeric fields
        return sortDirection === 'asc' 
            ? String(aValue).localeCompare(String(bValue))
            : String(bValue).localeCompare(String(aValue));
    });

    // Then apply pagination to the sorted list
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentUsers = sortedUsers.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(sortedUsers.length / itemsPerPage);

    // First sort the complete list
    const sortedTickets = [...(ticketList || [])].sort((a, b) => {
        // Always convert IDs to numbers first
        const aId = Number(a.id);
        const bId = Number(b.id);
        
        if (ticketSortField === 'id') {
            // Simple numeric comparison
            return ticketSortDirection === 'asc' ? aId - bId : bId - aId;
        }

        // For other fields
        const aValue = a[ticketSortField];
        const bValue = b[ticketSortField];
        
        if (ticketSortField === 'status') {
            return ticketSortDirection === 'asc' ? a.status - b.status : b.status - a.status;
        }
        
        // String comparison for non-numeric fields
        return ticketSortDirection === 'asc' 
            ? String(aValue).localeCompare(String(bValue))
            : String(bValue).localeCompare(String(aValue));
    });

    // Then filter (if needed)
    const filteredTickets = sortedTickets.filter(ticket => 
        showClosedTickets ? 
        ticket.status === TicketStatusEnum.Closed : 
        ticket.status !== TicketStatusEnum.Closed
    );

    // Finally paginate
    const ticketIndexOfLastItem = ticketCurrentPage * ticketsPerPage;
    const ticketIndexOfFirstItem = ticketIndexOfLastItem - ticketsPerPage;
    const currentTickets = filteredTickets.slice(ticketIndexOfFirstItem, ticketIndexOfLastItem);
    const totalTicketPages = Math.ceil(filteredTickets.length / ticketsPerPage);

    // Add this helper function if you don't already have it
    const isWithinLastThreeDays = (dateString) => {
        const date = new Date(dateString);
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        return date >= threeDaysAgo;
    };

    return(
        user && user?.role >= RoleEnum.Admin ?
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Admin Dashboard
                </h2>
            }>
            <div className="py-12 mx-3 lg:mx-0">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                        <DashboardCard className="md:col-span-2">
                            <div className="flex items-center gap-6">
                                <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] flex items-center justify-center">
                                    <span className="text-2xl font-bold text-white">
                                        {user?.name?.[0]?.toUpperCase()}
                                    </span>
                                </div>
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900">
                                        Welcome to the admin center, <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee]">{user?.name}</span>
                                    </h1>
                                    <p className="text-gray-600 mt-1">Here are some tools for admins!</p>
                                </div>
                            </div>
                        </DashboardCard>

                        
                        <DashboardCard>
                            <h2 id="quick-buttons" className="text-[2rem] mb-4">
                                Admin tools
                            </h2>
                            <BaseButton 
                                url={"/dashboard/admin/test/questions"} 
                                styles={"px-4 py-2 mr-3 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition-colors"}
                            >
                                Test questions
                            </BaseButton>
                            <BaseButton 
                                url={"/dashboard/admin/tools/"} 
                                styles={"px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition-colors"}
                            >
                                Tools
                            </BaseButton>
                        </DashboardCard>
                                
                        <DashboardCard>
                            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-12 items-center">
                                <div className="flex items-center">
                                    <Award className={"text-yellow-600 w-8 h-8 mr-2"} />
                                    <p className={"mt-5 ml-2"}>Certificates: {totalCertificates}</p>
                                </div>
                                <div className="flex items-center">
                                    <Users className={"text-blue-600 w-8 h-8 mr-2"} />
                                    <p className={"mt-5 ml-2"}>Users: {userList.length}</p>
                                </div>
                                <div className="flex items-center">
                                    <Users className={"text-red-600 w-8 h-8 mr-2"} />
                                    <p className={"mt-5 ml-2"}>Banned: {bannedUsersCount}</p>
                                </div>
                            </div>
                        </DashboardCard>
                    </div>

                    <DashboardCard className="mb-4">
                        <div className="overflow-x-auto">
                            <table className="w-full text-left table-auto">
                                <thead className="bg-gray-200">
                                <tr>
                                    {[
                                        { id: 'id', label: 'ID' },
                                        { id: 'name', label: 'Username' },
                                        { id: 'role', label: 'Role' },
                                        { 
                                            id: 'created_at', 
                                            label: <span className="whitespace-nowrap">
                                                Created at
                                            </span>
                                        },
                                        { id: 'tests', label: 'Tests taken', center: true },
                                        { id: 'roleOption', label: 'Role', center: true },
                                        { id: 'banned_user', label: 'Ban', center: true },
                                        { id: 'edit', label: 'Edit', noSort: true }
                                    ].map(column => (
                                        <th 
                                            key={column.id}
                                            onClick={() => {
                                                if (sortField === column.id) {
                                                    setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
                                                } else {
                                                    setSortField(column.id);
                                                    setSortDirection('asc');
                                                }
                                            }}
                                            className={`px-4 py-2 ${column.center ? 'text-center' : ''} cursor-pointer hover:bg-gray-300 select-none`}
                                        >
                                            <div className={`flex ${column.center ? 'text-center justify-center' : ''} gap-2`}>
                                                {column.label}
                                                {sortField === column.id && (
                                                    <span className="text-gray-500">
                                                        {sortDirection === 'asc' ? '↑' : '↓'}
                                                    </span>
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {currentUsers.length === 0 ? (
                                    <tr className="border-b border-gray-200">
                                        <td colSpan={5} className={"p-5"}>No users found.</td>
                                    </tr>
                                ) : (
                                    (currentUsers.map((currentUser) => (
                                    <tr key={currentUser.id} 
                                        className={`border-b border-gray-200 
                                            ${currentUser.banned_user ? 'line-through' : ''} 
                                            ${isWithinLastThreeDays(currentUser.created_at) ? 'bg-blue-50 dark:bg-blue-900/20' : ''}
                                            hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200`}
                                    >
                                        <td className="px-4 py-2">{currentUser.id}</td>
                                        <td className="px-4 py-2">{currentUser.name}</td>
                                        <td className="px-4 py-2">
                                            <RoleLabel role={currentUser.role} />
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="flex items-center">
                                                <span>
                                                    {new Date(currentUser.created_at).toLocaleString('en-US',
                                                        { month: 'long', day: '2-digit', year: 'numeric'})
                                                    }
                                                </span>
                                                {isWithinLastThreeDays(currentUser.created_at) && (
                                                    <span className="ml-2 text-xs text-blue-600 dark:text-blue-400 font-medium">
                                                        New
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {currentUser?.tests?.some(test => test !== null) ? (
                                                currentUser.tests.find(test => test !== null).in_progress ? (
                                                    <Loader className={"text-blue-700 mx-auto animate-spin"} />
                                                ) : (
                                                    currentUser.tests.some(test => test.certificate_id !== null) ? (
                                                        <CheckCircle className={"text-green-700 mx-auto"} />
                                                    ) : (
                                                        <XCircle className={"text-red-500 mx-auto"} />
                                                    )
                                                )
                                            ) : (
                                                <XCircle className={"text-gray-400 mx-auto"} />
                                            )}
                                        </td>
                                        <td className="text-center">
                                            {user.role >= RoleEnum.Admin && user.role > currentUser.role && user.id !== currentUser.id && (
                                                <>
                                                    {currentUser.role >= RoleEnum.DefaultUser && (
                                                    <Button onClick={() => upgradeAccountToTakeTests(currentUser.id, RoleEnum.Registered)}
                                                            title={"Downgrade account to take test"}
                                                            className={"mt-1"}
                                                            disabled={currentUser.banned_user}
                                                    >
                                                        <ArrowDownCircle className={`mx-auto ${currentUser.banned_user ? 'text-gray-300 ' : 'text-red-500 cursor-pointer hover:text-red-800'}`} />
                                                    </Button>)}
                                                    {currentUser.role === RoleEnum.Registered && (
                                                    <Button onClick={() => upgradeAccountToTakeTests(currentUser.id, RoleEnum.DefaultUser)}
                                                            title={"upgrade account to take test"} className={"mt-1"}
                                                            disabled={currentUser.banned_user}>
                                                        <ArrowUpCircle className={`mx-auto ${currentUser.banned_user ? 'text-gray-300 ' : 'text-blue-500 cursor-pointer hover:text-blue-800'}`} />
                                                    </Button>)}
                                                </>
                                            )}
                                        </td>
                                        <td className="px-4 py-2 text-center">
                                            {user.role >= RoleEnum.SuperAdmin && user.id !== currentUser.id &&
                                                (currentUser.banned_user ? (
                                                <Button onClick={() => unBanAccount(currentUser.id)} title={"Unban account"} className={"mt-1"}>
                                                    <Unlock className={"text-green-700 mx-auto cursor-pointer hover:text-red-800"} />
                                                </Button>
                                            ) : (
                                                <Button onClick={() => banAccount(currentUser.id)} title={"Ban account"} className={"mt-1"}>
                                                    <Ban className={"text-red-500 mx-auto cursor-pointer hover:text-red-800"} />
                                                </Button>
                                            ))}
                                        </td>
                                        <td className="px-4 py-2 text-center">
                                            <Button 
                                                onClick={() => navigate(`/dashboard/admin/user/${currentUser.id}`)} 
                                                title="Edit user"
                                            >
                                                <Edit className="text-blue-500 mx-auto cursor-pointer hover:text-blue-800" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))))
                                }
                                </tbody>
                            </table>

                            <div className="flex justify-center mt-4 mb-2">
                                <Pagination 
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={setCurrentPage}
                                />
                            </div>
                        </div>
                    </DashboardCard>

                    <DashboardCard>
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Support Tickets</h3>
                        <div className="overflow-x-auto">
                            <table className="w-full text-left table-auto">
                                <thead className="bg-gray-200">
                                <tr>
                                    {[
                                        { id: 'id', label: 'ID' },
                                        { id: 'user_id', label: '(user_id) User' },
                                        { id: 'category', label: 'Category' },
                                        { id: 'status', label: 'Status' },
                                        { id: 'description', label: 'Description' },
                                        { 
                                            id: 'created_at', 
                                            label: <span className="whitespace-nowrap">
                                                Updated at<br />
                                                <span className="text-gray-500 dark:text-gray-400 text-[11px]">Created at</span>
                                            </span>
                                        },
                                        { id: 'reply', label: 'Reply', noSort: true },
                                        { id: 'close', label: 'Close', noSort: true }
                                    ].map(column => (
                                        <th 
                                            key={column.id}
                                            onClick={() => {
                                                if (column.noSort) return;
                                                if (ticketSortField === column.id) {
                                                    setTicketSortDirection(current => current === 'asc' ? 'desc' : 'asc');
                                                } else {
                                                    setTicketSortField(column.id);
                                                    setTicketSortDirection('asc');
                                                }
                                            }}
                                            className={`px-4 py-2 ${!column.noSort ? 'cursor-pointer hover:bg-gray-300' : ''} select-none`}
                                        >
                                            <div className="flex items-center gap-2">
                                                {column.label}
                                                {!column.noSort && ticketSortField === column.id && (
                                                    <span className="text-gray-500">
                                                        {ticketSortDirection === 'asc' ? '↑' : '↓'}
                                                    </span>
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {currentTickets.length === 0 ? (
                                        <tr className="border-b border-gray-200">
                                            <td colSpan={5} className={"p-5"}>No tickets found.</td>
                                        </tr>
                                    ) : (
                                        currentTickets.map((ticket) => (
                                            <tr key={ticket.id} 
                                                className={`border-b border-gray-200 
                                                    ${ticket.status === TicketStatusEnum.Closed ? 'line-through' : ''} 
                                                    ${isWithinLastThreeDays(ticket.updated_at) ? 'bg-blue-50 dark:bg-blue-900/20' : ''}
                                                    hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200`}
                                            >
                                                <td className="px-4 py-2">{ticket.id}</td>
                                                <td className="px-4 py-2">({ticket.user_id}) {ticket.name}</td>
                                                <td className="px-4 py-2">{ticket.category === 'REQUEST_TEST_ACCESS' ? 'TEST_ACCESS' : ticket.category.toUpperCase()}</td>
                                                <td className="px-4 py-2">
                                                    <div className="w-[100px] flex justify-start">
                                                        <StatusLabel status={ticket.status} />
                                                    </div>
                                                </td>
                                                <td className="px-4 py-2">{ticket.description}</td>
                                                <td className="px-4 py-2">
                                                    <div className="flex flex-col gap-0.5">
                                                        <span className={`text-md ${
                                                            isWithinLastThreeDays(ticket.updated_at) 
                                                                ? 'text-blue-600 dark:text-blue-400 font-medium' 
                                                                : 'text-gray-500 dark:text-gray-400'
                                                        }`}>
                                                            {new Date(ticket.updated_at).toLocaleString('en-US', { 
                                                                month: 'short', 
                                                                day: '2-digit',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: false
                                                            })}
                                                        </span>
                                                        <span className={`text-xs ${
                                                            isWithinLastThreeDays(ticket.created_at) 
                                                                ? 'text-blue-600 dark:text-blue-400 font-medium' 
                                                                : 'text-gray-500 dark:text-gray-400'
                                                        }`}>
                                                            {new Date(ticket.created_at).toLocaleString('en-US', { 
                                                                month: 'short', 
                                                                day: '2-digit',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: false
                                                            })}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className={"px-4 py-2 text-center"}>
                                                {(ticket.updates.length === 0 && ticket.status === TicketStatusEnum.Closed) ? null : (
                                                    <Button onClick={() => {replyToTicket(ticket.id)}}>
                                                        <div className="relative">
                                                            <MessageCircle size={27} className={"text-blue-500 cursor-pointer hover:text-blue-800"} />
                                                            <span className="absolute top-[0.3rem] right-[0.350rem] bg-blue-500 text-white rounded-full w-4 h-4 text-xs flex items-center justify-center">
                                                                {ticket.updates.length}
                                                            </span>
                                                        </div>
                                                    </Button>)}
                                                </td>
                                                <td className={"px-4 py-2 text-center"}>
                                                    {ticket.status === TicketStatusEnum.Closed ? null : (
                                                    <Button onClick={() => {closeTicket(ticket.id)}}>
                                                        <XCircle className={"text-red-500 cursor-pointer hover:text-red-800"} />
                                                    </Button>)}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>

                            <div className="relative mt-4 mb-2">
                                <div className="flex justify-center">
                                    <Pagination 
                                        currentPage={ticketCurrentPage}
                                        totalPages={totalTicketPages}
                                        onPageChange={setTicketCurrentPage}
                                    />
                                </div>

                                <div className="absolute right-0 top-1/2 -translate-y-1/2">
                                    <button
                                        onClick={() => setShowClosedTickets(!showClosedTickets)}
                                        className={`px-4 py-2 rounded-lg transition-colors ${
                                            showClosedTickets
                                            ? 'bg-gray-500 text-white hover:bg-gray-600'
                                            : 'bg-blue-500 text-white hover:bg-blue-600'
                                        }`}
                                    >
                                        {showClosedTickets ? 'Show Open Tickets' : 'Show Closed Tickets'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </DashboardCard>

                    {showReplyModal && (
                       <ReplyTicketModal
                            showReplyModal={showReplyModal}
                            setShowReplyModal={setShowReplyModal}
                            ticketList={ticketList}
                            currentTicketId={currentTicketId}
                            replyText={replyText}
                            setReplyText={setReplyText}
                            handleReplySubmit={handleReplySubmit}
                            status={ticketList.find(ticket => ticket.id === currentTicketId)?.status}
                        />
                    )}
                </div>
            </div>
        </AppLayout>
        :
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Authenticating...
                </h2>
            }>
        </AppLayout>
    )
}

export default Admin
