export const NoRecipeFound = () => {
    return(
        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4
            border border-dashed border-gray-200 dark:border-gray-700">
            <div className="flex items-center gap-4">
                <div className="rounded-full bg-gray-100 dark:bg-gray-700 p-2 flex-shrink-0">
                    <svg className="w-4 h-4 text-gray-400 dark:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" 
                        />
                    </svg>
                </div>
                <div>
                    <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                        No recipes yet
                    </h3>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                        Save your merged prompts as recipes to reuse them later.
                    </p>
                </div>
            </div>
        </div>
    );
}