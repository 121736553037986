import React from 'react';
import { Section } from './types/Section';
import { Recipe } from './types/Recipe';
import { PromptLibraryPrompt } from './types/PromptLibraryPrompt';
import { ViewMode } from './types/ViewMode';
import { SectionHeader } from './SectionHeader';
import { NoRecipeFound } from './NoRecipeFound';
import { PromptRecipeCard } from './PromptRecipeCard';
import { PromptSections } from './PromptSections';
import { Tool } from './types/Tool';

interface ShowSectionsProps {
    prompts: PromptLibraryPrompt[];
    tools: Tool[];
    recipes: Recipe[];
    viewMode: ViewMode;
    copiedId: string | null;
    mergedPrompts: PromptLibraryPrompt[];
    searchQuery: string;
    selectedTools: string[];
    selectedLabels: string[];
    handleCopy: (prompt: string, id: string) => void;
    handleLoadRecipe: (recipe: Recipe) => void;
    toggleRecipePublish: (recipeId: string) => void;
    handleDeleteRecipe: (recipeId: string) => void;
    filterPrompts: (params: {
        prompts: PromptLibraryPrompt[];
        searchQuery: string;
        selectedTools: string[];
        selectedLabels: string[];
    }) => PromptLibraryPrompt[];
    handleOpenModal: (section: Section) => void;
    handleDeletePrompt: (id: string) => void;
    handleAddToMerged: (prompt: PromptLibraryPrompt) => void;
}

export const ShowSections: React.FC<ShowSectionsProps> = ({
    prompts,
    tools,
    recipes,
    viewMode,
    copiedId,
    mergedPrompts,
    searchQuery,
    selectedTools,
    selectedLabels,
    handleCopy,
    handleLoadRecipe,
    toggleRecipePublish,
    handleDeleteRecipe,
    filterPrompts,
    handleOpenModal,
    handleDeletePrompt,
    handleAddToMerged
}) => {
    // Add sorting function
    const sortPromptsByType = (prompts: PromptLibraryPrompt[]): PromptLibraryPrompt[] => {
        const typeOrder = {
            'component': 0,
            'example': 1,
            'template': 2,
            undefined: 0  // treat undefined as 'component'
        };

        return [...prompts].sort((a, b) => {
            const typeA = a.type || 'component';
            const typeB = b.type || 'component';
            return typeOrder[typeA] - typeOrder[typeB];
        });
    };

    return (
        <div className="space-y-8">
            {(["Context", "Register", "Acting Role", "Format", "Task", "Recipes"] as const).map((section) => {
                // For regular sections, show prompts
                if (section !== "Recipes") {
                    const sectionPrompts = sortPromptsByType(
                        filterPrompts({
                            prompts,
                            searchQuery,
                            selectedTools,
                            selectedLabels
                        }).filter(prompt => prompt.section === section)
                    );
                    
                    return <PromptSections 
                        key={section} 
                        section={section} 
                        sectionPrompts={sectionPrompts} 
                        viewMode={viewMode}
                        copiedId={copiedId}
                        mergedPrompts={mergedPrompts}
                        tools={tools}
                        handleCopy={handleCopy}
                        handleAddToMerged={handleAddToMerged}
                        handleDeletePrompt={handleDeletePrompt}
                        handleOpenModal={handleOpenModal}
                    />;
                }
                
                // For Recipes section
                return (
                    <div key={section} className="space-y-3">
                        <SectionHeader section={section} />
                        
                        {recipes.length === 0 ? (
                            <NoRecipeFound />
                        ) : (
                            <PromptRecipeCard
                                recipes={recipes}
                                viewMode={viewMode}
                                copiedId={copiedId}
                                handleCopy={handleCopy}
                                handleLoadRecipe={handleLoadRecipe}
                                toggleRecipePublish={toggleRecipePublish}
                                handleDeleteRecipe={handleDeleteRecipe}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};