import { Tool } from "./types/Tool";
import { ViewMode } from "./types/ViewMode";

interface SearchOptionsProps {
    searchQuery: string;
    setSearchQuery: (query: string) => void;
    selectedTools: string[];
    setSelectedTools: (tools: string[]) => void;
    selectedLabels: string[];
    setSelectedLabels: (labels: string[]) => void;
    showSections: boolean;
    setShowSections: (show: boolean) => void;
    showPublishedRecipes: boolean;
    setShowPublishedRecipes: (show: boolean) => void;
    allToolsSelected: boolean;
    setAllToolsSelected: (selected: boolean) => void;
    allLabelsSelected: boolean;
    setAllLabelsSelected: (selected: boolean) => void;
    getUsedTools: () => Tool[];
    getUniqueLabels: () => string[];
    resetFilters: () => void;
    viewMode: ViewMode;
    setViewMode: (mode: ViewMode) => void;
}

export const SearchOptions = ({
    searchQuery,
    setSearchQuery,
    selectedTools,
    setSelectedTools,
    selectedLabels,
    setSelectedLabels,
    showSections,
    setShowSections,
    showPublishedRecipes,
    setShowPublishedRecipes,
    allToolsSelected,
    setAllToolsSelected,
    allLabelsSelected,
    setAllLabelsSelected,
    getUsedTools,
    getUniqueLabels,
    resetFilters,
    viewMode,
    setViewMode
}: SearchOptionsProps) => {
    return(
        <div className="space-y-4 mt-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Search Input */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Search Prompts
                    </label>
                    <div className="relative">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search by title or content..."
                            className="w-full px-4 py-2 pl-10 rounded-lg border border-gray-200 dark:border-gray-700
                                bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                        />
                        <svg className="w-5 h-5 absolute left-3 top-2.5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                </div>

                {/* Tools Filter */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Filter by Tool
                    </label>
                    <select
                        value={allToolsSelected ? ['all'] : selectedTools}
                        onChange={(e) => {
                            const selected = Array.from(e.target.selectedOptions, option => option.value);
                            if (selected.includes('all')) {
                                setSelectedTools([]);
                                setAllToolsSelected(true);
                            } else {
                                setSelectedTools(selected);
                                setAllToolsSelected(false);
                            }
                        }}
                        className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700
                            bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                        multiple
                        disabled={getUsedTools().length === 0}
                    >
                        <option value="all">All Tools</option>
                        {getUsedTools().map(tool => (
                            <option key={tool.id} value={tool.id}>{tool.name}</option>
                        ))}
                    </select>
                </div>

                {/* Labels Filter */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Filter by Labels
                    </label>
                    <select
                        value={allLabelsSelected ? ['all'] : selectedLabels}
                        onChange={(e) => {
                            const selected = Array.from(e.target.selectedOptions, option => option.value);
                            if (selected.includes('all')) {
                                setSelectedLabels([]);
                                setAllLabelsSelected(true);
                            } else {
                                setSelectedLabels(selected);
                                setAllLabelsSelected(false);
                            }
                        }}
                        className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700
                            bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                        multiple
                        disabled={getUniqueLabels().length === 0}
                    >
                        <option value="all">All Labels</option>
                        {getUniqueLabels().map(label => (
                            <option key={label} value={label}>{label}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Add this new row for toggles */}
            <div className="flex justify-between items-center">
                <div>
                    <label className="flex items-center gap-3 text-sm font-medium text-gray-700 dark:text-gray-300">
                        <div className="relative inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                checked={showPublishedRecipes}
                                onChange={(e) => setShowPublishedRecipes(e.target.checked)}
                                className="sr-only peer"
                            />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
                                peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer 
                                dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                                after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white 
                                after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
                                after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                            />
                        </div>
                        <div className="flex flex-col">
                            <span>Show Published Recipes</span>
                            <span className="text-xs text-gray-500 dark:text-gray-400">
                                {showPublishedRecipes ? 'Showing all recipes' : 'Showing only your recipes'}
                            </span>
                        </div>
                    </label>
                </div>

                {/* Add ViewToggle here */}
                <div className="flex items-center gap-2">
                    <button
                        onClick={() => {
                            setViewMode('grid');
                            setShowSections(false);
                        }}
                        className={`p-2 rounded-lg transition-colors duration-200 ${
                            viewMode === 'grid' && !showSections
                                ? 'bg-blue-50 text-blue-600 dark:bg-blue-900/20 dark:text-blue-400'
                                : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
                        }`}
                        title="Grid View"
                    >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" 
                            />
                        </svg>
                    </button>
                    <button
                        onClick={() => {
                            setViewMode('grid');
                            setShowSections(true);
                        }}
                        className={`p-2 rounded-lg transition-colors duration-200 ${
                            viewMode === 'grid' && showSections
                                ? 'bg-blue-50 text-blue-600 dark:bg-blue-900/20 dark:text-blue-400'
                                : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
                        }`}
                        title="Grid with Sections"
                    >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" 
                            />
                        </svg>
                    </button>
                    <button
                        onClick={() => {
                            setViewMode('table');
                            setShowSections(true);
                        }}
                        className={`p-2 rounded-lg transition-colors duration-200 ${
                            viewMode === 'table' && showSections
                                ? 'bg-blue-50 text-blue-600 dark:bg-blue-900/20 dark:text-blue-400'
                                : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
                        }`}
                        title="Table View"
                    >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2zM9 9h6v6H9V9z" 
                            />
                        </svg>
                    </button>
                </div>
            </div>

            {/* Reset Filters Button */}
            {(searchQuery || !allToolsSelected || !allLabelsSelected) && (
                <div className="flex justify-end">
                    <button
                        onClick={resetFilters}
                        className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-600 hover:text-gray-900
                            dark:text-gray-400 dark:hover:text-white transition-colors"
                    >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        Reset Filters
                    </button>
                </div>
            )}
        </div>
    );
};