import { useEffect, useState } from "react";
import { ResponseFormat } from "../types/ModelParemetersResponseFormat";
import { ModelParameters } from "../interfaces/ModelParameters";

interface RecipeFormProps {
    onSave: (
        title: string, 
        description: string, 
        isPublished: boolean, 
        modelParams: ModelParameters
    ) => void;
    onClose: () => void;
}
export const RecipeFormModal: React.FC<RecipeFormProps> = ({ onSave, onClose }) => {
    const [showAdvanced, setShowAdvanced] = useState(false);

    const defaultModelParams = {
        model: '',
        responseFormat: 'text' as ResponseFormat,
        temperature: 0.0,
        maxTokens: 1,
        topP: 0,
        frequencyPenalty: 0,
        presencePenalty: 0,
    };

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        isPublished: false,
        modelParams: { ...defaultModelParams }
    });

    useEffect(() => {
        // Save the current overflow style
        const originalStyle = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling on cleanup
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []); // Empty dependency array means this runs once on mount and cleanup

    const handleSliderChange = (field: keyof ModelParameters, value: number) => {
        setFormData(prev => ({
            ...prev,
            modelParams: { ...prev.modelParams, [field]: value }
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const { model, responseFormat, temperature, maxTokens, topP, frequencyPenalty, presencePenalty } = formData.modelParams;
        
        // Create a new object with only the changed parameters
        const changedModelParams: Partial<ModelParameters> = {};
        
        if (model !== defaultModelParams.model) changedModelParams.model = model;
        if (responseFormat !== defaultModelParams.responseFormat) changedModelParams.responseFormat = responseFormat as ResponseFormat;
        if (temperature !== defaultModelParams.temperature) changedModelParams.temperature = temperature;
        if (maxTokens !== defaultModelParams.maxTokens) changedModelParams.maxTokens = maxTokens;
        if (topP !== defaultModelParams.topP) changedModelParams.topP = topP;
        if (frequencyPenalty !== defaultModelParams.frequencyPenalty) changedModelParams.frequencyPenalty = frequencyPenalty;
        if (presencePenalty !== defaultModelParams.presencePenalty) changedModelParams.presencePenalty = presencePenalty;

        onSave(
            formData.title,
            formData.description,
            formData.isPublished,
            Object.keys(changedModelParams).length > 0 ? changedModelParams as ModelParameters : {} as ModelParameters
        );
    };

    return (
        <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-[60] p-4 sm:p-6 overflow-y-auto"
        onClick={(e) => {
            if (e.target === e.currentTarget) {
                onClose();
            }
        }}
    >
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl w-full max-w-lg my-4" 
            onClick={e => e.stopPropagation()}>
            <div className="px-5 py-4 border-b border-gray-100 dark:border-gray-700">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Save Recipe
                </h2>
            </div>

            <form onSubmit={handleSubmit} className="p-5 space-y-4 max-h-[calc(90vh-8rem)] overflow-y-auto">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Title
                        </label>
                        <input
                            type="text"
                            value={formData.title}
                            onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                            className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700
                                bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                            placeholder="Enter recipe title..."
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Description
                        </label>
                        <textarea
                            value={formData.description}
                            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                            className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700
                                bg-white dark:bg-gray-800 text-gray-900 dark:text-white h-24"
                            placeholder="Describe what this recipe does..."
                            required
                        />
                    </div>

                    <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                        <button
                            type="button"
                            onClick={() => setShowAdvanced(!showAdvanced)}
                            className="flex items-center gap-2 text-sm font-medium text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                        >
                            <svg
                                className={`w-4 h-4 transition-transform ${showAdvanced ? 'rotate-180' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                            Advanced Model Parameters
                        </button>

                        {showAdvanced && (
                            <div className="mt-4 space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                        Model
                                    </label>
                                    <input
                                        type="text"
                                        value={formData.modelParams.model}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            modelParams: { ...prev.modelParams, model: e.target.value }
                                        }))}
                                        className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700
                                            bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                                        placeholder="e.g., gpt-4"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                        Response Format
                                    </label>
                                    <select
                                        value={formData.modelParams.responseFormat}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            modelParams: { ...prev.modelParams, responseFormat: e.target.value as ResponseFormat }
                                        }))}
                                        className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700
                                            bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                                    >
                                        <option value="text">Text</option>
                                        <option value="json_object">JSON Object</option>
                                        <option value="json_schema">JSON Schema</option>
                                    </select>
                                </div>

                                <div className="space-y-6">
                                    <div>
                                        <div className="flex justify-between items-center mb-1">
                                            <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                                Temperature
                                            </label>
                                            <span className="text-sm text-gray-500 dark:text-gray-400">
                                                {formData.modelParams.temperature.toFixed(2)}
                                            </span>
                                        </div>
                                        <input
                                            type="range"
                                            min="0"
                                            max="2"
                                            step="0.01"
                                            value={formData.modelParams.temperature}
                                            onChange={(e) => handleSliderChange('temperature', parseFloat(e.target.value))}
                                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                        />
                                    </div>

                                    <div>
                                        <div className="flex justify-between items-center mb-1">
                                            <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                                Max Tokens
                                            </label>
                                            <span className="text-sm text-gray-500 dark:text-gray-400">
                                                {formData.modelParams.maxTokens}
                                            </span>
                                        </div>
                                        <input
                                            type="range"
                                            min="1"
                                            max="16383"
                                            value={formData.modelParams.maxTokens}
                                            onChange={(e) => handleSliderChange('maxTokens', parseInt(e.target.value))}
                                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                        />
                                    </div>

                                    <div>
                                        <div className="flex justify-between items-center mb-1">
                                            <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                                Top P
                                            </label>
                                            <span className="text-sm text-gray-500 dark:text-gray-400">
                                                {formData.modelParams.topP.toFixed(2)}
                                            </span>
                                        </div>
                                        <input
                                            type="range"
                                            min="0"
                                            max="1"
                                            step="0.01"
                                            value={formData.modelParams.topP}
                                            onChange={(e) => handleSliderChange('topP', parseFloat(e.target.value))}
                                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                        />
                                    </div>

                                    <div>
                                        <div className="flex justify-between items-center mb-1">
                                            <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                                Frequency Penalty
                                            </label>
                                            <span className="text-sm text-gray-500 dark:text-gray-400">
                                                {formData.modelParams.frequencyPenalty.toFixed(2)}
                                            </span>
                                        </div>
                                        <input
                                            type="range"
                                            min="0"
                                            max="2"
                                            step="0.01"
                                            value={formData.modelParams.frequencyPenalty}
                                            onChange={(e) => handleSliderChange('frequencyPenalty', parseFloat(e.target.value))}
                                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                        />
                                    </div>

                                    <div>
                                        <div className="flex justify-between items-center mb-1">
                                            <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                                Presence Penalty
                                            </label>
                                            <span className="text-sm text-gray-500 dark:text-gray-400">
                                                {formData.modelParams.presencePenalty.toFixed(2)}
                                            </span>
                                        </div>
                                        <input
                                            type="range"
                                            min="0"
                                            max="2"
                                            step="0.01"
                                            value={formData.modelParams.presencePenalty}
                                            onChange={(e) => handleSliderChange('presencePenalty', parseFloat(e.target.value))}
                                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex items-center justify-between">
                        <label className="flex items-center gap-3 text-sm font-medium text-gray-700 dark:text-gray-300">
                            <input
                                type="checkbox"
                                checked={formData.isPublished}
                                onChange={(e) => setFormData(prev => ({ ...prev, isPublished: e.target.checked }))}
                                className="rounded border-gray-300"
                            />
                            Publish Recipe
                        </label>
                    </div>

                    <div className="flex justify-end gap-3 mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700
                                transition-colors text-sm font-medium"
                        >
                            Save Recipe
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};