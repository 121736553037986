import { Section } from "./types/Section";

export const SectionHeader = ({ section }: { section: Section }) => {
    return (
    <div className="col-span-full mt-8 mb-4">
        <div className="flex items-center gap-3">
            <h2 className="text-lg font-medium text-gray-800 dark:text-white">{section}</h2>
            <div className="flex-grow h-px bg-gray-200 dark:bg-gray-700"></div>
            </div>
        </div>
    );
}