import React from "react";
import {ShieldCheck, GraduationCap} from 'lucide-react';

const TicketUpdate = ({ update }) => {
    return (
        <div className={`mb-4 rounded-lg overflow-hidden
            ${update.user.role >= 3 
                ? 'bg-purple-50 border border-purple-100' 
                : update.user.role === 2 
                    ? 'bg-red-50 border border-red-100' 
                    : 'bg-blue-50 border border-blue-100'
            }`}
        >
            {/* Header with user info */}
            <div className="px-4 py-3 border-b border-opacity-20 flex items-center justify-between">
                <div className="flex items-center gap-2">
                    {/* User initial avatar */}
                    <div className={`w-8 h-8 rounded-full flex items-center justify-center
                        ${update.user.role >= 3 
                            ? 'bg-purple-100' 
                            : update.user.role === 2 
                                ? 'bg-red-100' 
                                : 'bg-blue-100'
                        }`}
                    >
                        <span className="text-sm font-semibold">
                            {update.user.name[0].toUpperCase()}
                        </span>
                    </div>

                    {/* Username and role icon */}
                    <div className="flex items-center gap-2">
                        <span className="font-medium">
                            {update.user.name}
                        </span>
                        {update.user.role >= 3 ? (
                            <ShieldCheck className="text-purple-500" size={16} />
                        ) : update.user.role === 2 ? (
                            <GraduationCap className="text-red-500" size={16} />
                        ) : null}
                    </div>
                </div>

                {/* Timestamp */}
                <span className="text-xs text-gray-500">
                    {new Date(update.updated_at).toLocaleString('en-US', { 
                        month: 'long', 
                        day: '2-digit', 
                        year: 'numeric', 
                        hour: '2-digit', 
                        minute: '2-digit', 
                        hour12: false 
                    })}
                </span>
            </div>

            {/* Message content */}
            <div className="p-4">
                <p className="text-gray-700">
                    {update.description}
                </p>
            </div>
        </div>
    );
};

export default TicketUpdate;