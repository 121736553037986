import React, {useState, useEffect} from 'react';
import { Info, Eye, EyeOff, AlertCircle, Trophy, Star, Target, Zap, Shield, Book, Award, Crown, MessageCircle, Share } from 'lucide-react';
import {useAuth} from "../../../hooks/auth";
import axios from "../../../libs/axios";
import RoleLabel from '../RoleLabel';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const achievements = [
    {
        id: 'quick_learner',
        name: 'Quick Learner',
        description: 'Completed profile setup',
        icon: Zap,
        unlocked: true, // Always unlocked as they have a profile
        color: 'text-blue-500'
    },
    {
        id: 'ticket_solver',
        name: 'Ticket Solver',
        description: 'Closed 5 tickets',
        icon: Target,
        unlocked: false, // This will be updated based on closedTicketsCount
        color: 'text-green-500'
    },
    {
        id: 'security_first',
        name: 'Security First',
        description: 'Reviewed Terms of Service',
        icon: Shield,
        unlocked: false, // This will be updated based on localStorage
        color: 'text-purple-500'
    },
    {
        id: 'first_rating',
        name: 'First Rating',
        description: 'Rated your first tool',
        icon: Star,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-orange-500'
    },
    {
        id: 'rating_enthusiast',
        name: 'Rating Enthusiast',
        description: 'Rated 10 tools',
        icon: Book,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-indigo-500'
    },
    {
        id: 'rating_expert',
        name: 'Rating Expert',
        description: 'Rated 20 tools',
        icon: Trophy,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-yellow-500'
    },
    {
        id: 'first_review',
        name: 'First Review',
        description: 'Reviewed your first tool',
        icon: Star,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-orange-500'
    },
    {
        id: 'review_enthusiast',
        name: 'Review Enthusiast',
        description: 'Reviewed 10 tools',
        icon: MessageCircle,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-indigo-500'
    },
    {
        id: 'review_expert',
        name: 'Review Expert',
        description: 'Reviewed 20 tools',
        icon: Trophy,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-yellow-500'
    },    
    {
        id: 'share_tools',
        name: 'Share Tools',
        description: 'Share your tools with the community',
        icon: Share,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-green-500'
    },  
    {
        id: 'first_certificate',
        name: 'First Certificate',
        description: 'Earned your first certificate',
        icon: Award,
        unlocked: false, // This will be updated based on certificateCount
        color: 'text-yellow-500'
    },
    {
        id: 'master',
        name: 'Master',
        description: 'Earned all certificates',
        icon: Crown,
        unlocked: false, // This will be updated based on certificateCount
        color: 'text-yellow-500'
    },
];

const TermsOfServiceModal = ({ isOpen, setIsOpen }) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white mb-4"
                                >
                                    Terms of Service
                                </Dialog.Title>
                                <div className="mt-2 space-y-4 text-gray-600 dark:text-gray-300">
                                    <p className="font-medium">
                                        Before accessing the "Prompt Injections" examples on our website, please read and agree to the following terms of service:
                                    </p>
                                    <ul className="list-disc pl-5 space-y-2">
                                        <li><span className="font-semibold">Educational Purpose Only:</span> The "Prompt Injections" examples provided are intended solely for educational purposes. They are meant to help you understand how prompt injections work and how to defend yourself against them.</li>
                                        <li><span className="font-semibold">No Misuse:</span> You agree not to use the provided examples for any malicious or unethical activities. This includes, but is not limited to, using prompt injections to manipulate, deceive, or harm others.</li>
                                        <li><span className="font-semibold">Responsible Use:</span> By accessing these examples, you confirm that your intention is to learn about the risks associated with prompt injections and to enhance your ability to safeguard against them.</li>
                                        <li><span className="font-semibold">Legal Compliance:</span> You agree to comply with all applicable laws and regulations while using the information provided on this website.</li>
                                        <li><span className="font-semibold">No Liability:</span> We are not responsible for any misuse of the information provided on our website. Users are solely responsible for their actions and any consequences that may arise from the use of this information.</li>
                                    </ul>
                                </div>

                                <div className="mt-6 flex justify-end gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

const ProfileEdit = () => {
    const { user } = useAuth({ middleware: 'auth' });
    const [certificateCount, setCertificateCount] = useState(0);
    const [ticketsCount, setTicketsCount] = useState(0);
    const [closedTicketsCount, setClosedTicketsCount] = useState(0);
    const [userAchievements, setUserAchievements] = useState(achievements);
    const [ratingsCount, setRatingsCount] = useState(0);
    const [toolReviewCount, setToolReviewCount] = useState(0);
    const [showExamples, setShowExamples] = useState(false);
    const [shareTools, setShareTools] = useState(false);

    useEffect(() => {
        if (user && user.profile) {
            setShowExamples(user.profile.show_examples);
            setShareTools(user.profile.show_tool_profile);
        }
    }, [user]);

    const getHasCertificateOne = async () => {
        try {
            const response = await axios.post("/quiz/verify-certificate");
            if (typeof response.data === 'object') {
                return response.data.certificate === "valid" ? 1 : 0;
            }
            return response.data;
        } catch(error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                window.location.reload();
            }
            return 0;
        }
    }

    const getUserTickets = async () => {
        try {
            const response = await axios.get(`/ticket/get`);
            const tickets = Array.isArray(response.data) ? response.data : [];
            
            // Count closed tickets (status 2)
            const closedCount = tickets.filter(ticket => ticket.status === 2).length;
            // Count active tickets (not status 2)
            const activeCount = tickets.filter(ticket => ticket.status !== 2).length;
            
            return { closedCount, activeCount };
        } catch(error) {
            console.error('Error fetching tickets:', error);
            return { closedCount: 0, activeCount: 0 };
        }
    }

    const getUserRatingsCount = async () => {
        try {
            const response = await axios.get('/user/ratings-count');
            return response.data.count || 0;
        } catch(error) {
            return 0;
        }
    }

    const getUserToolReviewCount = async () => {
        try {
            const response = await axios.get('/user/tools/reviewed');
            return response.data.count || 0;
        } catch(error) {
            return 0;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const [certificateData, ticketsData, ratingsData, toolReviewData] = await Promise.all([
                getHasCertificateOne(),
                getUserTickets(),
                getUserRatingsCount(),
                getUserToolReviewCount()
            ]);
            
            setCertificateCount(certificateData);
            setTicketsCount(ticketsData.activeCount);
            setClosedTicketsCount(ticketsData.closedCount);
            setRatingsCount(ratingsData);
            setToolReviewCount(toolReviewData);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const updatedAchievements = achievements.map(achievement => ({
            ...achievement,
            unlocked: 
                achievement.id === 'quick_learner' ? true :
                achievement.id === 'first_certificate' ? certificateCount > 0 :
                achievement.id === 'ticket_solver' ? closedTicketsCount >= 5 :
                achievement.id === 'security_first' ? showExamples :
                achievement.id === 'master' ? certificateCount >= 1 :
                achievement.id === 'first_rating' ? ratingsCount >= 1 :
                achievement.id === 'rating_enthusiast' ? ratingsCount >= 10 :
                achievement.id === 'rating_expert' ? ratingsCount >= 20 :
                achievement.id === 'first_review' ? toolReviewCount >= 1 :
                achievement.id === 'review_enthusiast' ? toolReviewCount >= 10 :
                achievement.id === 'review_expert' ? toolReviewCount >= 20 :
                achievement.id === 'share_tools' ? shareTools : false
        }));
        
        setUserAchievements(updatedAchievements);
    }, [certificateCount, closedTicketsCount, ticketsCount, ratingsCount, shareTools, toolReviewCount, showExamples]);

    const handleShowExamplesChange = async (e) => {
        const value = e.target.checked;
        setShowExamples(value);
        if (value) {
            localStorage.setItem('safetyTermsInjections', 'true');
        }else {
            localStorage.removeItem('safetyTermsInjections');
        }
        try {
            await axios.post('/profile/show-examples', {
                show_examples: value
            });
        } catch (error) {
            console.error('Error updating show examples:', error);
            setShowExamples(!value); // Revert on error
        }
    };

    const handleShareToolsChange = async (e) => {
        const value = e.target.checked;
        setShareTools(value);
               
        try {
            await axios.post('/profile/show-tool-profile', {
                show_tool_profile: value
            });
        } catch (error) {
            console.error('Error updating share tools:', error);
        }

        if (!user.profile) {
            window.location.reload();           
        }
        
    };

    const [isEditing, setIsEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteInput, setDeleteInput] = useState('');
    const [deleteError, setDeleteError] = useState('');

    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const [copiedLinkTooltip, setCopiedLinkTooltip] = useState(false);

    const handleDeleteClick = () => {
        setShowDeletePopup(true);
    };

    const handleDeleteConfirm = async () => {
        if (deleteInput === 'DELETE') {
            try {
                await axios.delete('/user/delete-account', { data: { delete: true } }).then(
                    response => {
                        // Logout user
                        setShowDeletePopup(false);
                        setDeleteError('');
                        localStorage.removeItem('username');
                        window.location.reload();
                    }
                );
            } catch (error) {
                setDeleteError('An error occurred while deleting your account. Please try again later.');
            }
        } else {
            setDeleteError('Incorrect input. Please enter "DELETE" to confirm.');
        }
        setDeleteInput('');
    };

    // const handleEdit = () => {
    //     setIsEditing(true);
    //     setError('');
    // };
    //
    // const handleCancel = () => {
    //     setIsEditing(false);
    //     setCurrentPassword('');
    //     setError('');
    // };
    //
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (currentPassword) {
    //         console.log('Submitting updated user data:', { ...user, currentPassword });
    //         setIsEditing(false);
    //         setCurrentPassword('');
    //         setError('');
    //     } else {
    //         setError('Please enter your current password to update your profile.');
    //     }
    // };
    //
    // const handlePasswordChange = (e) => {
    //     e.preventDefault();
    //     if (newPassword === confirmPassword) {
    //         console.log('Changing password:', { currentPassword, newPassword });
    //         setIsChangingPassword(false);
    //         setCurrentPassword('');
    //         setNewPassword('');
    //         setConfirmPassword('');
    //         setError('');
    //     } else {
    //         setError('New passwords do not match. Please try again.');
    //     }
    // };
    //
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     //setUser(prevUser => ({ ...prevUser, [name]: value }));
    // };

    const InputField = ({ label, name, value, onChange, disabled = false, type = "text" }) => (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className={`w-full py-3 border rounded-md ${disabled ? 'bg-white border-0' : 'bg-white px-3'}`}
            />
        </div>
    );

    const PasswordField = ({ label, value, onChange }) => (
        <div className="mb-4 relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
            <input
                type={showPassword ? "text" : "password"}
                value={value}
                onChange={onChange}
                className="w-full px-3 py-2 border rounded-md pr-10"
            />
            <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            >
                {showPassword ? <EyeOff className="h-5 w-5 text-gray-500" /> : <Eye className="h-5 w-5 text-gray-500" />}
            </button>
        </div>
    );

    const Button = ({ onClick, type, children, variant = "primary" }) => (
        <button
            onClick={onClick}
            type={type}
            className={`px-4 py-2 rounded-md ${
                variant === "primary"
                    ? "bg-blue-600 text-white hover:bg-blue-700" :
                    variant === "disabled" ? "bg-gray-200 text-gray-800 hover:bg-gray-300" :
                        variant === "warning" && "bg-red-400 text-white hover:bg-red-700"
            }`}
        >
            {children}
        </button>
    );

    const ErrorMessage = ({ message }) => (
        message ? (
            <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded relative" role="alert">
        <span className="flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
            {message}
        </span>
            </div>
        ) : null
    );

    const AchievementBadge = ({ achievement }) => {
        const Icon = achievement.icon;
        return (
            <div className="group relative flex flex-col items-center">
                <div className={`w-16 h-16 rounded-full flex items-center justify-center mb-2
                    transition-all duration-300 ${
                    achievement.unlocked 
                        ? `bg-${achievement.color.split('-')[1]}-100 dark:bg-${achievement.color.split('-')[1]}-900/30` 
                        : 'bg-gray-100 dark:bg-gray-800'
                    }`}
                >
                    <Icon className={`w-8 h-8 ${
                        achievement.unlocked 
                            ? achievement.color
                            : 'text-gray-400 dark:text-gray-600'
                    }`} />
                </div>
                <p className={`text-sm text-center font-medium transition-colors duration-200 max-w-[120px] ${
                    achievement.unlocked 
                        ? 'text-gray-900 dark:text-white' 
                        : 'text-gray-500 dark:text-gray-400'
                }`}>
                    {achievement.name}
                </p>
                
                {/* Tooltip */}
                <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute -top-16 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-xs rounded py-2 px-4 w-48 z-10">
                    <div className="text-center">
                        <p className="font-semibold mb-1">{achievement.name}</p>
                        <p className="text-gray-300">{achievement.description}</p>
                    </div>
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
                </div>
            </div>
        );
    };

    return (
        <div className=" mx-auto p-6 bg-white rounded-lg shadow-lg relative">
            <RoleLabel role={user.role} className="absolute top-2 right-2 m-4" />
            <h2 className="text-2xl font-bold mb-6">User Profile</h2>
            <ErrorMessage message={error} />
            {/*{!isChangingPassword ? (*/}
            {/*    <form onSubmit={handleSubmit}>*/}
                <form>
                    <InputField
                        label="Name"
                        name="name"
                        value={user.name}
                        // onChange={handleChange}
                        disabled={!isEditing}
                    />
                    <InputField
                        label="Email"
                        name="email"
                        value={user.email}
                        // onChange={handleChange}
                        disabled={true}
                    />
                    <p className="text-sm text-gray-500 mt-1 mb-4 flex items-center">
                        <Info className="w-4 h-4 mr-1" />
                        Username or email changes require a new ticket.
                    </p>
                    {/*{isEditing && (*/}
                    {/*    <PasswordField*/}
                    {/*        label="Current Password"*/}
                    {/*        value={currentPassword}*/}
                    {/*        onChange={(e) => setCurrentPassword(e.target.value)}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*<div className="mt-6 space-x-4">*/}
                    {/*    {!isEditing ? (*/}
                    {/*        <>*/}
                    {/*            <Button onClick={handleEdit} type="button">Edit Profile</Button>*/}
                    {/*            <Button onClick={() => {setIsChangingPassword(true); setError('');}} type="button" variant="secondary">Change Password</Button>*/}
                    {/*        </>*/}
                    {/*    ) : (*/}
                    {/*        <>*/}
                    {/*            <Button type="submit">Save Changes</Button>*/}
                    {/*            <Button onClick={handleCancel} type="button" variant="secondary">Cancel</Button>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </form>
            {/*) : (*/}
            {/*    <form onSubmit={handlePasswordChange}>*/}
            {/*        <PasswordField*/}
            {/*            label="Current Password"*/}
            {/*            value={currentPassword}*/}
            {/*            // onChange={(e) => setCurrentPassword(e.target.value)}*/}
            {/*        />*/}
            {/*        <PasswordField*/}
            {/*            label="New Password"*/}
            {/*            value={newPassword}*/}
            {/*            // onChange={(e) => setNewPassword(e.target.value)}*/}
            {/*        />*/}
            {/*        <PasswordField*/}
            {/*            label="Confirm New Password"*/}
            {/*            value={confirmPassword}*/}
            {/*            // onChange={(e) => setConfirmPassword(e.target.value)}*/}
            {/*        />*/}
            {/*        /!*<div className="mt-6 space-x-4">*!/*/}
            {/*        /!*    <Button type="submit">Change Password</Button>*!/*/}
            {/*        /!*    <Button onClick={() => {setIsChangingPassword(false); setError('');}} type="button" variant="secondary">Cancel</Button>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*    </form>*/}
            {/*)}*/}
            <div className="mt-6 flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                <div className="flex-1">
                    <h4 className="text-lg font-medium text-gray-900 dark:text-gray-100">Show Examples</h4>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        By enabling this option, you agree to our{' '}
                        <button
                            onClick={() => setIsTermsOpen(true)}
                            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 underline"
                        >
                            Terms of Service
                        </button>{' '}
                        and automatically see all examples while logged in.
                    </p>
                </div>
                <label className="inline-flex relative items-center cursor-pointer">
                    <input 
                        type="checkbox"
                        className="sr-only peer"
                        checked={showExamples}
                        onChange={handleShowExamplesChange}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
            </div>

            <div className="mt-6 flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                <div className="flex-1">
                    <h4 className="text-lg font-medium text-gray-900 dark:text-gray-100">Share Tools</h4>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                    Share your tools with the community. You can share your rated toollist. 
                    <br />Please note: Also your private tools will be shown!
                    <br />Enable to share your tools at:{' '}
                        {user.profile?.profile_id ? (
                            <>
                                <a 
                                    href={`${window.location.origin}/documentation/tools/profile/${user.profile.profile_id}`}
                                    className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 text-wrap break-words"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{wordBreak: 'break-word'}}
                                >
                                    {window.location.origin}/documentation/tools/profile/{user.profile.profile_id}
                                </a>
                                <br />
                                <button 
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${window.location.origin}/documentation/tools/profile/${user.profile.profile_id}`)
                                        setCopiedLinkTooltip(true)
                                        setTimeout(() => setCopiedLinkTooltip(false), 3000)
                                    }}
                                    className={`text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 mt-3 relative ${!shareTools ? 'text-gray-500 dark:text-gray-400 cursor-not-allowed' : ''}`}
                                    disabled={!shareTools}
                                >

                                    Copy your personal link
                                    {copiedLinkTooltip && (
                                        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-800 rounded-lg p-2 text-sm text-gray-500 dark:text-gray-400 shadow-lg">
                                            Copied!
                                        </div>
                                    )}
                                </button>
                            </>

                        ) : (
                            <span className="text-gray-500 dark:text-gray-400">
                                {window.location.origin}/documentation/tools/profile/your-profile-id

                                <br /><br />
                                Your ID will be generated once you enable this feature for the first time.
                            </span>
                        )}
                    </p>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                    <input 
                        type="checkbox"
                        className="sr-only peer"
                        checked={shareTools}
                        onChange={handleShareToolsChange}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
            </div>

            <div className="mt-6 rounded-lg bg-gray-50 dark:bg-gray-800 p-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Your Activity Statistics</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <span className="text-2xl font-bold text-sky-600 dark:text-sky-400 ml-5">
                                    {ticketsCount}
                                </span>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                                    Active Tickets
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <span className="text-2xl font-bold text-sky-600 dark:text-sky-400 ml-5">
                                    {closedTicketsCount}
                                </span>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                                    Tickets Closed
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <span className="text-2xl font-bold text-emerald-600 dark:text-emerald-400 ml-5">
                                    {certificateCount}
                                </span>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                                    Certificates Earned
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <span className="text-2xl font-bold text-sky-600 dark:text-sky-400 ml-5">
                                    {ratingsCount}
                                </span>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                                    Tools Rated
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <span className="text-2xl font-bold text-sky-600 dark:text-sky-400 ml-5">
                                    {toolReviewCount}
                                </span>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                                    Tools Reviewed
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
                        <div className="flex flex-col">
                            <p className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">
                                Profile Created
                            </p>
                            <div className="flex items-center gap-2">
                                <svg 
                                    className="w-5 h-5 text-emerald-500 dark:text-emerald-400" 
                                    fill="none" 
                                    stroke="currentColor" 
                                    viewBox="0 0 24 24"
                                >
                                    <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth={2} 
                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
                                    />
                                </svg>
                                <span className="text-md font-bold text-gray-900 dark:text-white">
                                    {new Date(user.created_at).toLocaleDateString('en-US', { 
                                        year: 'numeric', 
                                        month: 'long', 
                                        day: 'numeric' 
                                    })}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 bg-white dark:bg-gray-700 p-6 rounded-lg shadow">
                <div className="flex items-center justify-between mb-4">
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white">Achievements</h4>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                        {userAchievements.filter(a => a.unlocked).length} / {userAchievements.length} Unlocked
                    </span>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-6 justify-items-center">
                    {userAchievements.map((achievement) => (
                        <AchievementBadge 
                            key={achievement.id} 
                            achievement={achievement} 
                        />
                    ))}
                </div>
            </div>

            <div className="mt-6 flex justify-end">
                <Button onClick={handleDeleteClick} variant={"warning"}>Delete Account</Button>
            </div>

            {showDeletePopup && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded shadow-lg w-1/2">
                        <h4>Delete account</h4>
                        <p>Are you sure you want to delete your account? Please enter "DELETE" to confirm.</p>
                        <p>Please note: <span className={"text-red-700 font-bold"}>There is NO turning back!</span><br /> All your certificates, test results, tickets, and other achievements or activities will be permanently deleted.
                        <br />Your earned certificate(s) will become INVALID after this action.</p>
                        <p>If you have any concerns regarding your account or would like to discuss it first, please raise a ticket in your dashboard. We will reach out as soon as possible.</p>
                        <input
                            type="text"
                            value={deleteInput}
                            onChange={(e) => setDeleteInput(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm border border-gray-300 p-2"
                        />
                        {deleteError && <p className="text-red-500 mt-2">{deleteError}</p>}
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => { setShowDeletePopup(false); setDeleteError(''); setDeleteInput(''); }}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDeleteConfirm}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <TermsOfServiceModal isOpen={isTermsOpen} setIsOpen={setIsTermsOpen} />
        </div>
    );
};

export default ProfileEdit;