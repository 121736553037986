export const StarRating = ({ rating }: { rating: number | string }) => {
    // Parse the rating and round to 1 decimal place
    const numericRating = typeof rating === 'string' 
        ? parseFloat(rating) 
        : rating;
    
    return (
        <div className="flex items-center gap-1">
            {[1, 2, 3, 4, 5].map((star) => {
                const isFull = star <= Math.floor(numericRating);
                const isPartial = star === Math.ceil(numericRating) && numericRating % 1 !== 0;
                const fillPercentage = isPartial ? ((numericRating % 1) * 100) : 0;
                
                return (
                    <svg
                        key={star}
                        className="w-3.5 h-3.5"
                        viewBox="0 0 20 20"
                    >
                        {isPartial && (
                            <defs>
                                <linearGradient id={`star-gradient-${star}`} x1="0" x2="1" y1="0" y2="0">
                                    <stop 
                                        offset="0%" 
                                        style={{ stopColor: '#facc15' }} 
                                    />
                                    <stop 
                                        offset={`${fillPercentage}%`} 
                                        style={{ stopColor: '#facc15' }} 
                                    />
                                    <stop 
                                        offset={`${fillPercentage}%`} 
                                        style={{ stopColor: 'rgb(209 213 219)' }} 
                                    />
                                    <stop 
                                        offset="100%" 
                                        style={{ stopColor: 'rgb(209 213 219)' }} 
                                    />
                                </linearGradient>
                            </defs>
                        )}
                        <path
                            className={isFull ? 'text-yellow-400' : 'text-gray-300 dark:text-gray-600'}
                            fill={isPartial ? `url(#star-gradient-${star})` : 'currentColor'}
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        />
                    </svg>
                );
            })}
        </div>
    );
};