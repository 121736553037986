import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect} from "react";
import QRCodeGenerator from "../../components/QRCodeGenerator";
import RoleEnum from "../../components/enums/RoleEnum";
import VerifyCertificateForm from "../../components/dashboard/dashboard/verify-certificate-form";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";

const Trainer = () => {
    const { user } = useAuth({ middleware: 'trainer' });

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Trainer"

        if (user && user?.role < RoleEnum.Trainer) {
            window.location.href = '/dashboard'
        }

    }, [user]);
    

    return(
        user && user?.role >= RoleEnum.Trainer ?
            <AppLayout
                header={
                    <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                        Trainer Dashboard
                    </h2>
                }>
                <div className="py-12 mx-3 lg:mx-0">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4">
                        <DashboardCard className="md:col-span-2 mb-4">
                            <div className="flex items-center gap-6">
                                <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] flex items-center justify-center">
                                    <span className="text-2xl font-bold text-white">
                                        {user?.name?.[0]?.toUpperCase()}
                                    </span>
                                </div>
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900">
                                        Welcome to the training center, <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee]">{user?.name}</span>
                                    </h1>
                                    <p className="text-gray-600 mt-1">Here are some tools for trainers!</p>
                                </div>
                            </div>
                        </DashboardCard>

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="flex flex-col">
                                <DashboardCard>
                                    <VerifyCertificateForm />
                                </DashboardCard>
                            </div>
                            <div className="flex flex-col">
                                <DashboardCard>
                                    <QRCodeGenerator />
                                </DashboardCard>
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout>
            :
            <AppLayout
                header={
                    <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                        Authenticating...
                    </h2>
                }>
            </AppLayout>
    )
}

export default Trainer
