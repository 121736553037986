import React from 'react';
import { GridItem } from './types/GridItem';
import { Tool } from './types/Tool';
import { ViewMode } from './types/ViewMode';
import { ToolBadge } from './ToolBadge';

interface PromptCardProps {
    prompt: GridItem;
    viewMode: ViewMode;
    copiedId: string | null;
    mergedPrompts: GridItem[];
    tools: Tool[];
    handleCopy: (prompt: string, id: string) => void;
    handleAddToMerged: (prompt: GridItem) => void;
    handleDeletePrompt: (id: string) => void;
}

export const PromptCard: React.FC<PromptCardProps> = ({ 
    prompt, 
    viewMode, 
    copiedId, 
    mergedPrompts, 
    tools,
    handleCopy, 
    handleAddToMerged, 
    handleDeletePrompt,
}) => {
    const truncateText = (text: string, maxLength: number = 100) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    if (viewMode === 'table') {
        return (
            <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700/50">
                <td className="px-4 py-3 font-medium text-gray-900 dark:text-white">
                    <div className="flex items-center gap-2">
                        {prompt.title}
                        {prompt.type === 'example' && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium
                                bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400"
                            >
                                Example
                            </span>
                        )}
                        {/* Template Badge */}
                        {prompt.type === 'template' && (
                            <span className="inline-block px-2.5 py-0.5 text-xs font-medium rounded-full
                                bg-cyan-50 text-cyan-600 dark:bg-cyan-900/20 dark:text-cyan-400"
                            >
                                Template
                            </span>
                        )}
                    </div>
                </td>
                <td className="px-4 py-3 text-gray-600 dark:text-gray-300">
                    <div className="group relative">
                        <span className="line-clamp-2 group-hover:line-clamp-none">
                            {prompt.prompt}
                        </span>
                        {prompt.prompt.length > 100 && (
                            <div className="absolute left-0 right-0 bottom-0 h-6 bg-gradient-to-t from-white dark:from-gray-800 group-hover:hidden" />
                        )}
                    </div>
                </td>
                <td className="px-4 py-3">
                    {prompt.labels.map(label => (
                        <span 
                            key={label}
                            className="inline-flex mb-2 items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                bg-gray-100 text-gray-600
                                dark:bg-gray-700 dark:text-gray-300 mr-1"
                        >
                            {label}
                        </span>
                    ))}
                </td>
                <td className="px-4 py-3">
                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => handleCopy(prompt.prompt, prompt.id)}
                            className="p-1 text-blue-600 hover:text-blue-700 dark:text-blue-400"
                            title="Copy Prompt"
                        >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" 
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => handleAddToMerged(prompt)}
                            className={`p-1 ${
                                mergedPrompts.some(p => p.id === prompt.id)
                                    ? 'text-gray-400 dark:text-gray-600 cursor-not-allowed' 
                                    : 'text-blue-600 hover:text-blue-700 dark:text-blue-400'
                            }`}
                            title="Add to Merged"
                            disabled={mergedPrompts.some(p => p.id === prompt.id)}
                        >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                        </button>
                        <button
                            onClick={() => handleDeletePrompt(prompt.id)}
                            className="p-1 text-red-600 hover:text-red-700 dark:text-red-400"
                            title="Delete Prompt"
                        >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                                />
                            </svg>
                        </button>
                    </div>
                </td>
            </tr>
        );
    }

    // Return grid view
    return(
        <div 
            key={prompt.id}
            className="prompt-card bg-white dark:bg-gray-800 rounded-xl p-4 shadow-sm
                border border-gray-100 dark:border-gray-700
                hover:shadow-md transition-all duration-200
                flex flex-col"
        >
            <div className="space-y-2 mb-3">
                <div className="flex justify-between items-start">
                    <div className="flex gap-2">
                        {/* Section Label */}
                        <span className="inline-block px-2.5 py-0.5 text-xs font-medium rounded-full
                            bg-blue-50 text-blue-600 dark:bg-blue-900/20 dark:text-blue-400"
                        >
                            {prompt.section}
                        </span>
                        
                        {/* Example Badge */}
                        {prompt.type === 'example' && (
                            <span className="inline-block px-2.5 py-0.5 text-xs font-medium rounded-full
                                bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400"
                            >
                                Example
                            </span>
                        )}

                         {/* Template Badge */}
                         {prompt.type === 'template' && (
                            <span className="inline-block px-2.5 py-0.5 text-xs font-medium rounded-full
                                bg-cyan-50 text-cyan-600 dark:bg-cyan-900/20 dark:text-cyan-400"
                            >
                                Template
                            </span>
                        )}
                    </div>
                    
                    <button
                        onClick={() => handleDeletePrompt(prompt.id)}
                        className="p-1 text-gray-400 hover:text-red-500 dark:text-gray-500 
                            dark:hover:text-red-400 transition-colors"
                        title="Delete prompt"
                    >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                            />
                        </svg>
                    </button>
                </div>

                {/* Tool badges */}
                {prompt.toolId && tools.length > 0 && <ToolBadge toolId={prompt.toolId} tools={tools} />}
                
                <h3 className="text-base font-semibold text-gray-900 dark:text-white break-words">
                    {prompt.title}
                </h3>
            </div>

            <div className="group relative">
                <p className="text-gray-600 dark:text-gray-300 text-sm mb-3 line-clamp-3 group-hover:line-clamp-none transition-all duration-200">
                    {prompt.prompt}
                </p>
                {prompt.prompt.length > 100 && (
                    <div className="absolute left-0 right-0 bottom-0 h-6 bg-gradient-to-t from-white dark:from-gray-800 group-hover:hidden" />
                )}
            </div>

            <div className="flex flex-wrap gap-1.5 mb-4">
                    {prompt.labels.map(label => (
                        <span 
                            key={label}
                            className="px-2 py-0.5 text-xs font-medium rounded-full
                                bg-gray-100 text-gray-600
                                dark:bg-gray-700 dark:text-gray-300"
                        >
                            {label}
                        </span>
                    ))}
            </div>

            <div className="flex gap-2 mt-auto pt-2 border-t border-gray-100 dark:border-gray-700">
                <button
                    onClick={() => handleCopy(prompt.prompt, prompt.id)}
                    className={`flex items-center justify-center gap-1 px-3 py-1.5 rounded-full text-xs font-medium
                        transition-colors duration-200 flex-1
                        ${copiedId === prompt.id
                            ? 'bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400'
                            : 'bg-blue-50 text-blue-600 hover:bg-blue-100 dark:bg-blue-900/20 dark:text-blue-400 dark:hover:bg-blue-900/30'
                        }`}
                >
                    <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        {copiedId === prompt.id ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        ) : (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                        )}
                    </svg>
                </button>
                <button
                    onClick={() => handleAddToMerged(prompt)}
                    className={`flex items-center justify-center gap-1 px-3 py-1.5 rounded-full text-xs font-medium
                        transition-colors duration-200 flex-1
                        ${mergedPrompts.some(p => p.id === prompt.id)
                            ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500'
                            : 'bg-blue-50 text-blue-600 hover:bg-blue-100 dark:bg-blue-900/20 dark:text-blue-400 dark:hover:bg-blue-900/30'
                        }`}
                    disabled={mergedPrompts.some(p => p.id === prompt.id)}
                >
                    <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        {mergedPrompts.some(p => p.id === prompt.id) ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        ) : (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        )}
                    </svg>
                </button>
            </div>
        </div>
    );
}