import RiskLabel from "../../../components/labels/RiskLabel";
import React from "react";
import AdversarialPromptingLabel from "../../../components/labels/AdversarialPromptingLabel";
import HiddenForSafety from '../../../components/safety/HiddenForSafety';

interface MemoryPromptInjectionProps {
  safetyCheck: boolean;
  showTermsOfService: boolean;
  setShowTermsOfService: (value: boolean) => void;
  setSafetyTermsPopup: (value: boolean) => void;
}

function MemoryPromptInjection({ 
  safetyCheck,
  showTermsOfService,
  setShowTermsOfService,
  setSafetyTermsPopup
}: MemoryPromptInjectionProps) {
    return(
        <>
            <div className={"flex col-3 mb-1"}  id="memory-injection">
                <h2 className={"mb-1"}><span className="text-header-gradient">Memory</span> Manipulation</h2>
                <div className={"mt-3 ml-4"}>
                    <RiskLabel category={"risk"} classifier="HIGH" /> <RiskLabel category={"impact"} classifier="HIGH" />
                </div>
                <div className={"mt-3 ml-4"}>
                    <AdversarialPromptingLabel category={"PI"} />
                </div>
            </div>

            <p>Since February 13, 2024, ChatGPT has been equipped with the ability to memorize what you've shared. This can be very useful as it allows ChatGPT to learn from what you share and use that information in later conversations.</p>
            <p>With this great new feature, however, significant risks also come into play. Here's how it works: ChatGPT can either add things to its memory on its own, which will be flagged above the output with: "memory updated", or it can update its memory at your request. For instance, you can add to your prompt: "Add X to your memory", and it will automatically update its memory.</p>
            <p>Now, this second method poses a definite risk. Imagine you're browsing a website, uploading a document, or using the new "Computer Use" feature to interact with an application on your computer that contains a prompt injection. In such cases, this prompt injection could potentially force ChatGPT to add something to its memory or even retrieve information from it without your consent.</p>

            <h3 id={"example-memory-injection"}>Examples</h3>
            {safetyCheck ? (
                <>
            <p>For example, imagine this memory update is made:</p>
            <img src={require("../../../assets/img/prompt-injections/memory-injection-1.png")} alt="Memory Injection Example" className="w-full" />

            <p>Now, every time you start a new conversation with ChatGPT, it will output what was set in its memory.</p>
            <img src={require("../../../assets/img/prompt-injections/memory-injection-2.png")} alt="Memory Injection Output Example" className="w-full" />

            <p>The risk here is that memory updates can also be hidden in other documents or even websites. For instance, imagine I embedded such a prompt within a large document that explains the Crafting AI Prompts Framework. This would result in the following:</p>
            <img src={require("../../../assets/img/prompt-injections/memory-injection-3.png")} alt="Memory Injection Output Example" className="w-full" />

            <p>And yes, this does update your memory:</p>
            <img src={require("../../../assets/img/prompt-injections/memory-injection-4.png")} alt="Memory Injection Output Example" className="w-full" />

            <p>This risk extends further. With the "Computer Use" function from ChatGPT, it can interact with applications on your computer. This means you could even hide prompts within applications (like your terminal) and update the memory of users—or yourself—through this method.</p>
            </>
        ) : (
            <HiddenForSafety 
              showTermsOfService={showTermsOfService}
              setShowTermsOfService={setShowTermsOfService}
              setSafetyTermsPopup={setSafetyTermsPopup}
            />
          )}
            <h3 id="memory-injection-risk-assessment">Risk Assessment</h3>
            <p>
                The tactic of Memory Manipulation via Prompt Injection is classified as <RiskLabel category="risk"
                                                                                                   classifier="HIGH"/> and
                with a <RiskLabel category="impact" classifier="HIGH"/>. This occurs when malicious actors exploit
                ChatGPT’s memory feature by embedding hidden prompts in documents, websites, or applications, leading to
                unintended memory updates. Once updated, this memory persists across sessions and can influence future
                interactions.
            </p>
            <p>
                The likelihood of this risk is high due to the ease with which such injections can be embedded in
                commonly uploaded sources such as documents, images, applications or even websites.
                Users may unknowingly trigger
                these updates by interacting with maliciously crafted content. The impact is equally high (<RiskLabel
                category="impact" classifier="HIGH"/>), as such injections can lead to persistent data retention,
                unauthorized manipulations, or security breaches, which may spread misinformation or compromise user
                privacy.
            </p>
            <p>
                This scenario underscores the need for strict safeguards to prevent unauthorized memory updates,
                including prompt injection detection, user transparency in memory updates, and robust controls.
                Educating users about these risks and ensuring secure handling of uploaded data are crucial to
                mitigating the high risk and impact of this vulnerability.
            </p>

            <hr className={"pb-5"} />
        </>);
}

export default MemoryPromptInjection;