import { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { DropResult } from 'react-beautiful-dnd';
import { useAuth } from "../../hooks/auth";
import { NewPrompt } from "../../components/PromptLibrary/types/NewPrompt";
import { PromptLibraryPrompt } from "../../components/PromptLibrary/types/PromptLibraryPrompt";
import { Tool } from "../../components/PromptLibrary/types/Tool";
import { Section } from "../../components/PromptLibrary/types/Section";
import { AddPromptModal } from "../../components/PromptLibrary/modals/AddPromptModal";
import { RecipeFormModal } from "../../components/PromptLibrary/modals/RecipeFormModal";
import { GridItem } from "../../components/PromptLibrary/types/GridItem";
import { ViewMode } from "../../components/PromptLibrary/types/ViewMode";
import { MergedPrompts } from "../../components/PromptLibrary/MergedPrompts";
import { SearchOptions } from "../../components/PromptLibrary/SearchOptions";
import { NewPromptButton } from "../../components/PromptLibrary/NewPromptButton";
import { filterPrompts } from "../../components/PromptLibrary/functions/FilterPrompts";
import { Recipe } from "../../components/PromptLibrary/types/Recipe";
import { ShowSections } from "../../components/PromptLibrary/ShowSections";
import { WarnLocalStorage } from "../../components/PromptLibrary/temp/WarnLocalStorage";
import { DeleteRecipeModal } from "../../components/PromptLibrary/modals/DeleteRecipeModal";
import { DeletePromptModal } from "../../components/PromptLibrary/modals/DeleteModal";
import { ShowTableLayout } from "../../components/PromptLibrary/ShowTableLayout";
import { ShowNoSections } from "../../components/PromptLibrary/ShowNoSections";
import { PROMPT_SECTIONS, PROMPT_SECTIONS_MERGED_PROMPTS } from "../../components/PromptLibrary/constants/sections";

function PromptLibrary() {
    const { user } = useAuth({ 
        middleware: 'trainer',
        redirectIfAuthenticated: false 
    });

    const [prompts, setPrompts] = useState<PromptLibraryPrompt[]>([
        // {
        //     id: "1",
        //     title: "Professional Email",
        //     prompt: "Write in a professional tone, using formal language and business etiquette...",
        //     section: "Register",
        //     labels: ["example", "business"],
        //     toolId: ""
        // },
        // {
        //     id: "2",
        //     title: "Professional Test",
        //     prompt: "Write in a professional tone, using formal language and business etiquette...",
        //     section: "Context",
        //     labels: ["test"],
        //     toolId: ""
        // },
        // Add more sample prompts...
    ]);

    // State for merged prompts
    const [mergedPrompts, setMergedPrompts] = useState<PromptLibraryPrompt[]>([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [selectedSection, setSelectedSection] = useState<Section>("Context");
    const sectionOrder = ["Context", "Register", "Acting Role", "Format", "Task"];
    const [viewMode, setViewMode] = useState<ViewMode>('grid');
    const [copiedId, setCopiedId] = useState<string | null>(null);

    const [tools, setTools] = useState<Tool[]>([]);
    const [isLoadingTools, setIsLoadingTools] = useState(true);
    const [toolsError, setToolsError] = useState(false);
    const [allToolsSelected, setAllToolsSelected] = useState(true);
    const [allLabelsSelected, setAllLabelsSelected] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTools, setSelectedTools] = useState<string[]>([]);
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

    const [recipes, setRecipes] = useState<Recipe[]>([]);
    const [isRecipeModalOpen, setIsRecipeModalOpen] = useState(false);

    const [showPublishedRecipes, setShowPublishedRecipes] = useState(true);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [promptToDelete, setPromptToDelete] = useState<string | null>(null);

    // Fetch tools on component mount
    useEffect(() => {
        const fetchTools = async () => {
            setIsLoadingTools(true);
            try {
                const response = await axios.get('/tools');
                setTools(response.data);
                setToolsError(false);
            } catch (error) {
                setToolsError(true); // TODO: SHOULD DO SOMETHING WITH THIS
            } finally {
                setIsLoadingTools(false);
            }
        };
    
        fetchTools();
    }, []);

    const handleCopy = (text: string, promptId: string) => {
        navigator.clipboard.writeText(text);
        setCopiedId(promptId);
        setTimeout(() => {
            setCopiedId(null);
        }, 2000);
    };

    const handleAddToMerged = (prompt: PromptLibraryPrompt) => {
        const sectionOrder = PROMPT_SECTIONS_MERGED_PROMPTS;
        
        // If there are no merged prompts, just add the new one
        if (mergedPrompts.length === 0) {
            setMergedPrompts([prompt]);
            return;
        }
    
        // Find where this section should be inserted
        const promptSectionIndex = sectionOrder.indexOf(prompt.section);
        const insertIndex = mergedPrompts.findIndex(p => 
            sectionOrder.indexOf(p.section) > promptSectionIndex
        );
    
        // If no later sections found, add to the end
        if (insertIndex === -1) {
            setMergedPrompts([...mergedPrompts, prompt]);
            return;
        }
    
        // Insert at the correct position
        const newMergedPrompts = [...mergedPrompts];
        newMergedPrompts.splice(insertIndex, 0, prompt);
        setMergedPrompts(newMergedPrompts);
    };

    const handleRemoveFromMerged = (promptId: string) => {
        setMergedPrompts(mergedPrompts.filter(p => p.id !== promptId));
    };

    const getMergedText = () => {
        return mergedPrompts
            .map(prompt => `<${prompt.section}>${prompt.prompt}</${prompt.section}>`)
            .join('\n\n');
    };

    const handleAddPrompt = async (newPrompt: NewPrompt) => {
        try {
            // Add loading state if needed
            const prompt: PromptLibraryPrompt = {
                ...newPrompt,
                id: Date.now().toString(), // or use uuid if available
            };
            
            // Update state with new prompt
            setPrompts(prevPrompts => {
                const updatedPrompts = [...prevPrompts, prompt];
                /** TODO: If you have an API endpoint for adding prompts */
                return updatedPrompts;
            });            
            // Close modal
            setIsAddModalOpen(false);
            
            // Optional: Scroll to new prompt
            setTimeout(() => {
                document.getElementById(prompt.id)?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        } catch (error) {}
    };

    const handleOpenModal = (section: Section) => {
        setSelectedSection(section);
        setIsAddModalOpen(true);
    };

    // Add this function to reset all filters
    const resetFilters = () => {
        setSearchQuery('');
        setSelectedTools([]);
        setSelectedLabels([]);
        setAllToolsSelected(true);
        setAllLabelsSelected(true);
    };

    // Update the getUniqueLabels function to only return used labels
    const getUniqueLabels = () => {
        const labels = new Set<string>();
        prompts.forEach(prompt => {
            if (prompt.labels && prompt.labels.length > 0) {
                prompt.labels.forEach(label => labels.add(label));
            }
        });
        return Array.from(labels);
    };

    // Update the getUsedTools function to get tools that are actually used in prompts
    const getUsedTools = () => {
        const usedToolIds = new Set(prompts
            .map(prompt => prompt.toolId)
            .filter(toolId => toolId && toolId !== '')); // Filter out empty tool IDs
        
        return tools.filter(tool => usedToolIds.has(String(tool.id))); // Convert tool.id to string for comparison
    };

    // Update the delete handler
    const handleDeletePrompt = async (promptId: string) => {
        setPromptToDelete(promptId);
        setIsDeleteModalOpen(true);
    };

    // Add the confirmation handler
    const confirmDelete = async () => {
        if (promptToDelete) {
            try {
                // TODO: If you have an API endpoint for deleting prompts
                // await axios.delete(`/prompts/${promptToDelete}`);
                
                setPrompts(prompts.filter(p => p.id !== promptToDelete));
                setMergedPrompts(mergedPrompts.filter(p => p.id !== promptToDelete));
            } catch (error) {
                console.error('Failed to delete prompt:', error);
            }
        }
        setIsDeleteModalOpen(false);
        setPromptToDelete(null);
    };

    // Define the function inside PromptLibrary
    const handleSaveRecipe = (
        title: string, 
        description: string, 
        isPublished: boolean,
        modelParams: {
            temperature: number;
            maxTokens: number;
            topP: number;
            frequencyPenalty: number;
            presencePenalty: number;
        }
    ) => {
        const newRecipe: Recipe = {
            id: Date.now().toString(),
            title,
            description,
            prompts: [...mergedPrompts],
            createdAt: new Date(),
            isPublished,
            createdBy: user?.id || 'anonymous',
            modelParams // Add the model parameters
        };
    
        // Use functional update to ensure we have the latest state
        setRecipes(prevRecipes => { 
            console.log(...prevRecipes, newRecipe);
            return [...prevRecipes, newRecipe]; 
        });
        setMergedPrompts([]); // Clear merged prompts
        setIsRecipeModalOpen(false); // Close modal
    };

    // Add a type guard function
    const isRecipe = (item: GridItem | Recipe): item is Recipe => {
        return 'prompts' in item;
    };

    // Update the handleLoadRecipe function
    const handleLoadRecipe = (item: GridItem | Recipe) => {
        if (!isRecipe(item)) {
            // It's a GridItem, find the corresponding Recipe
            const recipe = recipes.find(r => r.id === item.id);
            if (recipe) {
                setMergedPrompts(recipe.prompts);
            }
        } else {
            // It's a Recipe
            setMergedPrompts(item.prompts);
        }
    };

    // Add these state variables for the delete confirmation modal
    const [isDeleteRecipeModalOpen, setIsDeleteRecipeModalOpen] = useState(false);
    const [recipeToDelete, setRecipeToDelete] = useState<string | null>(null);

    // Update the delete handler
    const handleDeleteRecipe = (recipeId: string) => {
        setRecipeToDelete(recipeId);
        setIsDeleteRecipeModalOpen(true);
    };

    // Add the confirmation handler
    const confirmDeleteRecipe = () => {
        if (recipeToDelete) {
            setRecipes(recipes.filter(recipe => recipe.id !== recipeToDelete));
        }
        setIsDeleteRecipeModalOpen(false);
        setRecipeToDelete(null);
    };

    // Update the getFilteredRecipes function to consider recipe ownership
    const getFilteredRecipes = () => {
        return recipes.filter(recipe => {
            // Show the recipe if:
            // 1. showPublishedRecipes is true (show all published recipes) OR
            // 2. The recipe is not published OR
            // 3. The recipe was created by the current user
            if (!showPublishedRecipes && recipe.isPublished && recipe.createdBy !== user?.id) {
                return false;
            }

            // Then apply the rest of the filters
            const matchesSearch = recipe.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                recipe.prompts.some(prompt => 
                    prompt.prompt.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    prompt.title.toLowerCase().includes(searchQuery.toLowerCase())
                );

            const matchesLabels = selectedLabels.length === 0 || 
                recipe.prompts.some(prompt => 
                    prompt.labels.some(label => selectedLabels.includes(label))
                );

            const matchesTools = selectedTools.length === 0 || 
                recipe.prompts.some(prompt => 
                    selectedTools.includes(prompt.toolId)
                );

            return matchesSearch && matchesLabels && matchesTools;
        });
    };

    // Add this handler function
    const handleClearMerged = () => {
        setMergedPrompts([]);
    };

    // Add this state
    const [showSections, setShowSections] = useState(false);

    // Helper function to get all items for the grid view
    const getAllItems = (): GridItem[] => {
        // Get regular prompts and cast them to GridItem
        const regularPrompts: GridItem[] = filterPrompts({
            prompts,
            searchQuery,
            selectedTools,
            selectedLabels
        }).map(prompt => ({
            ...prompt,
            isRecipe: false
        }));
        
        // Get recipes and format them like prompts
        const recipePrompts: GridItem[] = recipes.map(recipe => ({
            id: recipe.id,
            title: recipe.title,
            prompt: `Recipe with ${recipe.prompts.length} prompts`,
            section: "Recipes" as Section,
            labels: recipe.prompts.flatMap(p => p.labels),
            toolId: recipe.prompts[0]?.toolId || "",
            isRecipe: true
        }));

        // Combine and sort all items
        return [...regularPrompts, ...recipePrompts]
            .sort((a, b) => {
            const aIndex = sectionOrder.indexOf(a.section);
            const bIndex = sectionOrder.indexOf(b.section);
            const aType = a.type || 'component';
            const bType = b.type || 'component';
            const typeOrder = {
                'component': 0,
                'example': 1,
                'template': 2,
                undefined: 0  // treat undefined as 'component'
            };
            return aIndex - bIndex || typeOrder[aType] - typeOrder[bType];
        });
    };

    // Add this handler function
    const toggleRecipePublish = (recipeId: string) => {
        setRecipes(recipes.map(recipe => 
            recipe.id === recipeId 
                ? { ...recipe, isPublished: !recipe.isPublished }
                : recipe
        ));
    };

    // Update the onDragEnd function
    const onDragEnd = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        const newPrompts = Array.from(mergedPrompts);
        const [removed] = newPrompts.splice(result.source.index, 1);
        newPrompts.splice(result.destination.index, 0, removed);
        
        setMergedPrompts(newPrompts);
    };

    return (
        <section className="w-full flex dark:bg-slate-900 dark:text-white" id="prompt-library">
            <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
                {/* Temp: Warning Banner */}
                <WarnLocalStorage />

                {/* Main Content */}
                <div className="col-span-12 space-y-8 px-4 sm:px-6 mt-20 lg:col-span-9">
                    <div className="flex justify-between items-center">
                        <div className="space-y-6">
                            <h1 className="text-4xl font-bold">
                                Prompt <span className="text-header-gradient">Library</span>
                            </h1>
                            <p className="text-lg text-gray-600 dark:text-gray-300">
                                Store and manage your prompts using the Crafting AI Prompts Framework.
                            </p>
                        </div>
                        <NewPromptButton handleOpenModal={handleOpenModal} />
                    </div>

                    <SearchOptions
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        selectedTools={selectedTools}
                        setSelectedTools={setSelectedTools}
                        selectedLabels={selectedLabels}
                        setSelectedLabels={setSelectedLabels}
                        showSections={showSections}
                        setShowSections={setShowSections}
                        showPublishedRecipes={showPublishedRecipes}
                        setShowPublishedRecipes={setShowPublishedRecipes}
                        allToolsSelected={allToolsSelected}
                        setAllToolsSelected={setAllToolsSelected}
                        allLabelsSelected={allLabelsSelected}
                        setAllLabelsSelected={setAllLabelsSelected}
                        getUsedTools={getUsedTools}
                        getUniqueLabels={getUniqueLabels}
                        resetFilters={resetFilters}
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                    />

                    <div className="lg:hidden bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
                    <MergedPrompts 
                        mergedPrompts={mergedPrompts}
                        onDragEnd={onDragEnd}
                        handleRemoveFromMerged={handleRemoveFromMerged}
                        handleCopy={handleCopy}
                        handleClearMerged={handleClearMerged}
                        copiedId={copiedId}
                        setIsRecipeModalOpen={setIsRecipeModalOpen}
                        getMergedText={getMergedText}
                    />
                    </div>

                    {/* Sections */}
                    {showSections && viewMode === 'grid' ? (
                        <ShowSections
                            prompts={prompts}
                            tools={tools}
                            recipes={recipes}
                            viewMode={viewMode}
                            copiedId={copiedId}
                            mergedPrompts={mergedPrompts}
                            searchQuery={searchQuery}
                            selectedTools={selectedTools}
                            selectedLabels={selectedLabels}
                            handleCopy={handleCopy}
                            handleLoadRecipe={handleLoadRecipe}
                            toggleRecipePublish={toggleRecipePublish}
                            handleDeleteRecipe={handleDeleteRecipe}
                            filterPrompts={filterPrompts}
                            handleOpenModal={handleOpenModal}   
                            handleDeletePrompt={handleDeletePrompt} 
                            handleAddToMerged={handleAddToMerged}                    
                        />
                    ) : viewMode === 'table' ? (
                        <ShowTableLayout
                            viewMode={viewMode}
                            copiedId={copiedId}
                            mergedPrompts={mergedPrompts}
                            tools={tools}
                            recipes={recipes}
                            handleCopy={handleCopy}
                            handleAddToMerged={handleAddToMerged}
                            handleDeletePrompt={handleDeletePrompt}
                            handleOpenModal={handleOpenModal}
                            handleLoadRecipe={handleLoadRecipe}
                            toggleRecipePublish={toggleRecipePublish}
                            handleDeleteRecipe={handleDeleteRecipe}
                            getAllItems={getAllItems}
                            getFilteredRecipes={getFilteredRecipes}
                        />
                    ) : (
                        /* No Sections */
                        <ShowNoSections
                            viewMode={viewMode}
                            copiedId={copiedId}
                            mergedPrompts={mergedPrompts}
                            tools={tools}
                            recipes={recipes}
                            handleCopy={handleCopy}
                            handleAddToMerged={handleAddToMerged}
                            handleDeletePrompt={handleDeletePrompt}
                            handleOpenModal={handleOpenModal}
                            handleLoadRecipe={handleLoadRecipe}
                            toggleRecipePublish={toggleRecipePublish}
                            handleDeleteRecipe={handleDeleteRecipe}
                            getAllItems={getAllItems}
                            getFilteredRecipes={getFilteredRecipes}
                        />
                    )}
                </div>

                {/* Desktop Merged Prompts Sidebar */}
                <div className="hidden lg:block lg:col-span-3 mt-20">
                    <div className="sticky top-24 bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
                    <MergedPrompts 
                        mergedPrompts={mergedPrompts}
                        copiedId={copiedId}
                        onDragEnd={onDragEnd}
                        handleRemoveFromMerged={handleRemoveFromMerged}
                        handleCopy={handleCopy}
                        handleClearMerged={handleClearMerged}
                        setIsRecipeModalOpen={setIsRecipeModalOpen}
                        getMergedText={getMergedText}
                    />
                    </div>
                </div>
            </div>

            <AddPromptModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onSubmit={handleAddPrompt}
                initialSection={selectedSection}
                tools={tools}
                isLoadingTools={isLoadingTools}
            />

            {isDeleteModalOpen && (
                <DeletePromptModal 
                    isOpen={isDeleteModalOpen}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                        setPromptToDelete(null);
                    }}
                    onConfirm={confirmDelete}
                />
            )}
          
            {isRecipeModalOpen && (
                <RecipeFormModal
                    onSave={handleSaveRecipe}
                    onClose={() => setIsRecipeModalOpen(false)}
                />
            )}
            
            {isDeleteRecipeModalOpen && (
                <DeleteRecipeModal 
                    isOpen={isDeleteRecipeModalOpen}
                    onClose={() => {
                        setIsDeleteRecipeModalOpen(false);
                        setRecipeToDelete(null);
                    }}
                    onConfirm={confirmDeleteRecipe}
                />
            )}
        </section>
    );
}

export default PromptLibrary;