// Components
import {useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";
import DocumentationNav from "./DocumentationNav";
import Pagination from "../../components/dashboard/dashboard/Pagination";
import axios from "../../libs/axios";
import { useAuth } from "../../hooks/auth";
import { CATEGORIES, CategoryType } from '../../components/CategoryBadge/CategoryBadge';
import { Tool } from '../../components/PromptLibrary/types/Tool';
import { ToolRequest } from "../../components/Tools/types/ToolRequest";
import { SortOption } from "../../components/Tools/types/SortOption";
import { RatedFilter } from "../../components/Tools/types/RatedFilter";
import { DetailedViewTool } from "../../components/Tools/types/DetailedViewTool";
import { RatingViewMode } from "../../components/Tools/types/RatingViewMode";
import { SelectedCategories } from "../../components/Tools/types/SelectedCategories";
import { ToolItem } from "../../components/Tools/ToolItem";
import { DetailedView } from "../../components/Tools/DetailedView";
import { RequestToolModal } from "../../components/Tools/RequestToolModal";
import { UserIcon } from "lucide-react";

function Tools() {
    // Add this helper function near the top with other utility functions
    const canAddTools = (user: any) => {
        return user?.role >= 1;
    };

    const { userName } = useParams<{ userName?: string }>();
    const [profileUser, setProfileUser] = useState<any>(null);
    const [selectedCategories, setSelectedCategories] = useState<SelectedCategories>(new Set(["All" as CategoryType]));
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;
    const [detailedViewTool, setDetailedViewTool] = useState<DetailedViewTool>(null);
    const [allTools, setAllTools] = useState<Tool[]>([]);
    const [profileTools, setProfileTools] = useState<Tool[]>([]);
    const [displayedTools, setDisplayedTools] = useState<Tool[]>([]);
    const [isError, setIsError] = useState(false);
    const { user } = useAuth({ 
        middleware: 'auth',
        redirectIfAuthenticated: false 
    });
    const [sortBy, setSortBy] = useState<SortOption>("name");
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [ratingViewMode, setRatingViewMode] = useState<RatingViewMode>(userName ? "profile" : "average");
    const resultsRef = useRef<HTMLDivElement>(null);
    const [ratedFilter, setRatedFilter] = useState<RatedFilter>("all");
    const [pricingFilter, setPricingFilter] = useState<Tool['pricing']>(undefined);
    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
    const [isMultiSelect, setIsMultiSelect] = useState(false);
    const [profileError, setProfileError] = useState<string | null>(null);
    
    // Initial data fetch
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // Always fetch all tools
                const allToolsResponse = await axios.get('/tools');
                setAllTools(allToolsResponse.data);
                
                // If on profile page, also fetch profile tools
                if (userName) {
                    try {
                        const profileResponse = await axios.get(`/tools/profile/${userName}`);
                        if (profileResponse.data?.ratings?.length > 0) {
                            setProfileUser(profileResponse.data);
                            setProfileTools(profileResponse.data.ratings);
                            setProfileError(null);
                        } else {
                            setProfileError("This user hasn't rated any tools yet");
                            setProfileTools([]);
                        }
                    } catch (error) {
                        setIsError(true);
                        setProfileError("No profile found");
                        setProfileTools([]);
                    }
                }
                
                setIsError(false);
            } catch (error) {
                setProfileError("error");
            } finally {
                setIsLoading(false);
            }
        };
        
        fetchData();
    }, [userName]);

    // Update displayed tools when rating view mode changes
    useEffect(() => {
        if (ratingViewMode === "profile" && userName) {
            setDisplayedTools(profileTools);
        } else {
            setDisplayedTools(allTools);
        }
    }, [ratingViewMode, userName, profileTools, allTools]);

    // Add this function to get unique pricing options
    const getAvailablePricingOptions = () => {
        const options = [{ value: '', label: 'All' }];
        options.push({ value: 'free', label: 'Free' });
        options.push({ value: 'trail', label: 'Trial' });
        options.push({ value: 'paid', label: 'Paid' });
        return options;
    };

    // Updated filtered and sorted tools
    const filteredTools = displayedTools
    .filter(tool => {
        // Pricing filter
        if (pricingFilter) {
            const toolPricing = tool.pricing || '';
            if (pricingFilter === 'free' && !['free', 'both', 'free-trail-paid'].includes(toolPricing)) return false;
            if (pricingFilter === 'paid' && !['paid', 'both', 'trail', 'free-trail-paid'].includes(toolPricing)) return false;
            if (pricingFilter === 'trail' && !['trail', 'free-trail-paid'].includes(toolPricing)) return false;
        }

        // Only show verified tools when viewing "our ratings"
        if (ratingViewMode === "our" && tool.verified !== 1) return false;

        // First apply the rated/unrated/personal filter
        if (ratedFilter === "personal" && tool.verified !== 0) return false;

        if (ratedFilter === "reviewed" && tool.hasReviewed !== true) return false;
        
        // Consider a tool "rated" only if it has at least one non-zero rating
        const hasNonZeroRating = tool.userRatings && 
            Object.values(tool.userRatings).some(rating => rating > 0);
            
        if (ratedFilter === "rated" && !hasNonZeroRating) return false;
        if (ratedFilter === "unrated" && hasNonZeroRating) return false;
        
        // Then apply category and search filters
        const categoryMatch = selectedCategories.has("All" as CategoryType) || 
            Array.from(selectedCategories).every(category => category in tool.ratings);
            
        return categoryMatch && (
            searchQuery === '' || 
            tool.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tool.desc.toLowerCase().includes(searchQuery.toLowerCase())
        );
    })
        .sort((a, b) => {
            switch (sortBy) {
                case "name":
                    return a.name.localeCompare(b.name);
                
                case "rating": {
                    const getAvgRating = (tool: Tool) => {
                        // If "All" is selected, use all categories
                        if (selectedCategories.has("All" as CategoryType)) {
                            const ratings = Object.values(
                                ratingViewMode === "user" ? (tool.userRatings || {}) :
                                ratingViewMode === "our" ? (tool.ourRatings || {}) :
                                ratingViewMode === "profile" ? (tool.ratings || {}) :
                                tool.ratings
                            )
                            .map(rating => Number(rating))
                            .filter(rating => rating > 0);
                            
                            return ratings.length > 0
                                ? ratings.reduce((sum, r) => sum + r, 0) / ratings.length
                                : 0;
                        }
                        
                        // Otherwise, only use selected categories
                        const selectedRatings = Array.from(selectedCategories)
                            .map(category => {
                                const rating = ratingViewMode === "user" 
                                    ? tool.userRatings?.[category] 
                                    : ratingViewMode === "our" 
                                        ? tool.ourRatings?.[category]
                                        : ratingViewMode === "profile"
                                            ? tool.ratings?.[category]
                                            : tool.ratings[category];
                                return Number(rating) || 0;
                            })
                            .filter(rating => rating > 0);
                        
                        return selectedRatings.length > 0
                            ? selectedRatings.reduce((sum, r) => sum + r, 0) / selectedRatings.length
                            : 0;
                    };

                    const aAvg = getAvgRating(a);
                    const bAvg = getAvgRating(b);

                    // If ratings are equal, sort alphabetically as secondary sort
                    return bAvg === aAvg ? a.name.localeCompare(b.name) : bAvg - aAvg;
                }
                
                default:
                    return 0;
            }
        });

    // Calculate pagination
    const indexOfLastTool = currentPage * itemsPerPage;
    const indexOfFirstTool = indexOfLastTool - itemsPerPage;
    const totalPages = Math.ceil(filteredTools.length / itemsPerPage);
    const currentTools = filteredTools.slice(indexOfFirstTool, indexOfLastTool);

    // Add this useEffect to reset to first page when category changes
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedCategories, searchQuery, ratedFilter, ratingViewMode, pricingFilter]);

    // Update the user check to only verify login status
    const userIsLoggedIn = () => {
        return !!user;
    };

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Tools"
    }, [user]); // Add user as dependency

    // Add a function to refresh tools
    const refreshTools = async () => {
        try {
            const response = await axios.get('/tools');
            setAllTools(response.data);
            
            // If there's a detailed view open, update it with the new data
            if (detailedViewTool) {
                const updatedTool = response.data.find((t: Tool) => t.id === detailedViewTool.id);
                if (updatedTool) {
                    setDetailedViewTool(updatedTool);
                }
            }
            
            setIsError(false);
        } catch (error) {
            setIsError(true);
        }
    };

    // Add this loading skeleton component
    const LoadingSkeleton = () => (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
            {[...Array(6)].map((_, idx) => (
                <div key={idx} className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 space-y-4 border border-gray-100 dark:border-gray-700">
                    {/* Header skeleton */}
                    <div className="flex justify-between items-start">
                        <div className="h-6 w-32 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                        <div className="h-8 w-20 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse" />
                    </div>
                    
                    {/* Description skeleton */}
                    <div className="space-y-2">
                        <div className="h-4 w-full bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                        <div className="h-4 w-2/3 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                    </div>
                    
                    {/* Categories skeleton */}
                    <div className="pt-2 border-t border-gray-100 dark:border-gray-700">
                        <div className="h-4 w-32 bg-gray-200 dark:bg-gray-700 rounded mb-4 animate-pulse" />
                        <div className="space-y-2">
                            {[...Array(3)].map((_, i) => (
                                <div key={i} className="flex justify-between items-center">
                                    <div className="h-6 w-24 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse" />
                                    <div className="h-4 w-20 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const EmptyState = ({ searchQuery, selectedCategories }: { searchQuery: string, selectedCategories: SelectedCategories }) => (
        <div className="text-center py-12">
            <div className="flex justify-center mb-4">
                <svg 
                    className="w-24 h-24 text-gray-300 dark:text-gray-600" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path 
                        d="M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                    <path 
                        d="M10 7V13" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                    <path 
                        d="M7 10H13" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
                No tools found
            </h3>
            <p className="text-gray-500 dark:text-gray-400 max-w-md mx-auto">
                {searchQuery 
                    ? <>
                        No tools match "<span className="font-medium text-gray-700 dark:text-gray-300">{searchQuery}</span>"
                        {selectedCategories.has("All") ? "" : ` in ${Array.from(selectedCategories).join(", ")}`}
                      </>
                    : selectedCategories.has("All")
                        ? <>No tools available at the moment</>
                        : "No tools available in " + Array.from(selectedCategories).join(", ")
                }
            </p>
            <div className="mt-6">
                <button
                    onClick={() => {
                        setSearchQuery('');
                        setSelectedCategories(new Set(["All" as CategoryType]));
                        setCurrentPage(1);
                        setRatedFilter('all');
                        setRatingViewMode('average');
                        setPricingFilter(undefined);
                    }}
                    className="inline-flex items-center px-4 py-2 border border-transparent 
                        rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 
                        hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                        focus:ring-blue-500 transition-colors duration-200"
                >
                    <svg 
                        className="mr-2 -ml-1 h-5 w-5" 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 20 20" 
                        fill="currentColor"
                    >
                        <path 
                            fillRule="evenodd" 
                            d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" 
                            clipRule="evenodd" 
                        />
                    </svg>
                    Reset filters
                </button>
            </div>
        </div>
    );

    // Update the pagination handler to include scrolling
    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
        // Scroll to results section with a small delay to ensure content has updated
        setTimeout(() => {
            resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
    };

    // Add useEffect to reset page when search query changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]); // Reset to page 1 whenever search query changes

    // Add handler for tool requests
    const handleToolRequest = async (request: ToolRequest) => {
        try {
            await axios.post('/tools/user', request);
            // Refresh tools list after successful submission
            await refreshTools();
            // Close the modal
            setIsRequestModalOpen(false);
            // Optionally add a success message/notification here
        } catch (error) {
            // Handle error appropriately
            throw error;
        }
    };

    // Add delete handler
    const handleDeleteTool = async (toolId: string) => {
        try {
            await axios.delete(`/tools/${toolId}`);
            // Refresh tools list after successful deletion
            await refreshTools();
        } catch (error) {
            throw error;
        }
    };

    const handleViewAllTools = () => {
        setRatingViewMode("average");
        setProfileError(null);
        setIsError(false);
        const fetchAllTools = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get('/tools');
                setAllTools(response.data);
                setIsError(false);
            } catch (error) {
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAllTools();
    };

    const renderContent = () => {
        if (profileError) {
            return (
                <div className="flex flex-col items-center justify-center min-h-[400px] w-full bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                    <div className="p-8 text-center">
                        <div className="inline-flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-gray-100 dark:bg-gray-700">
                            <svg className="w-8 h-8 text-gray-400 dark:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                        </div>
                        <h3 className="mb-2 text-2xl font-semibold text-gray-900 dark:text-white">
                            {profileError}
                        </h3>
                        <p className="mb-6 text-gray-600 dark:text-gray-400">
                            Check the username or try viewing all tools instead. <br />It might be that the user has not rated any tools yet, <br />or did turn this feature off in their settings.
                        </p>
                        <button
                            onClick={handleViewAllTools}
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-offset-gray-800 transition-colors duration-150"
                        >
                            View All Tools
                            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                            </svg>
                        </button>
                    </div>
                </div>
            );
        }

        if (isLoading) {
            return <LoadingSkeleton />;
        }

        if (filteredTools.length === 0) {
            return <EmptyState searchQuery={searchQuery} selectedCategories={selectedCategories} />
        }

        return (
            <>                
                {profileUser && ratingViewMode === "profile" && (
                    <div className="bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg flex items-center px-4">
                        <UserIcon className="w-6 h-6 mr-2 text-gray-500 dark:text-gray-400 self-center mr-5" />
                        <p className="text-sm font-medium text-gray-800 dark:text-gray-200 pt-5">
                            You are currently viewing only tools and ratings from <strong>{profileUser.name}</strong>.
                        </p>
                        <span className="text-sm font-medium ml-auto">
                            <button
                                onClick={() => setRatingViewMode("user")}
                                className="text-blue-600 dark:text-blue-400 hover:underline mr-2"
                            >
                                Show my tools
                            </button>
                            |
                            <button
                                onClick={() => setRatingViewMode("average")}
                                className="text-blue-600 dark:text-blue-400 hover:underline ml-2"
                            >
                                Show all tools
                            </button>
                        </span>

                    </div>
                )}

                {/* Grid of tools */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                    {currentTools.map((tool) => (
                        <ToolItem
                            key={tool.id}
                            tool={tool}
                            ratingViewMode={ratingViewMode}
                            selectedCategory={selectedCategories.size > 0 ? Array.from(selectedCategories)[0] : null}
                            selectedCategories={selectedCategories}
                            onViewDetails={setDetailedViewTool}
                            profileUser={profileUser}
                        />
                    ))}
                </div>

                {/* Pagination */}
                {!isLoading && filteredTools.length > itemsPerPage && (
                    <div className="mt-8">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="prompt-techniques">
            <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
                <DocumentationNav page="Tools" />

                <div className="col-span-12 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section lg:col-span-9"
                     id="tools"
                     data-aos="fade-up"
                     data-aos-delay="100">

        
                    {/* <div className="bg-blue-50 dark:bg-blue-900/50 border-l-4 border-blue-500 rounded-lg shadow-sm 
                        px-6 py-1 backdrop-blur-sm transition-all duration-200">
                        <div className="flex items-center gap-3">
                            <svg 
                                className="flex-shrink-0 h-5 w-5 text-blue-500 dark:text-blue-400" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"                                            
                                    />
                            </svg>
                            <p className="text-blue-700 dark:text-blue-200 font-medium leading-5 pt-5">
                                This page is only visible to logged in users. 
                                {!userIsLoggedIn() && (
                                    <span className="ml-1">
                                        Please <a 
                                            href="/login" 
                                            className="text-blue-600 dark:text-blue-300 underline decoration-2 underline-offset-2 
                                                hover:text-blue-800 dark:hover:text-blue-100 font-semibold transition-colors duration-200"
                                        >
                                            login
                                        </a> if you cannot see the content.
                                    </span>
                                )}
                            </p>
                        </div>
                    </div> */}

                    {userIsLoggedIn() &&
                        <>
                            <h1 className="text-bold"><span className="text-header-gradient">Tools</span></h1>
                            <p>Welcome to our curated collection of Generative AI tools! While the AI landscape offers thousands of options, we've carefully selected and tested these tools across various projects. As a logged-in user, you can contribute by rating tools based on your experience, helping others make informed decisions.</p>
                            <p>{canAddTools(user) ? (
                                <>Know a great tool that's missing from our list? You can add it directly using the "Add New Tool" button. Please note that newly added tools will only be visible to you until we verify them and add them to the default list. 
                                Also, in your <a href="/dashboard/profile" className="text-blue-600 dark:text-blue-300 underline decoration-2 underline-offset-2 
                                    hover:text-blue-800 dark:hover:text-blue-100 font-semibold transition-colors duration-200">profile</a> you can share your tools!</>
                            ) : (
                                <>You can add/rate tools when you request access to the test.</>
                            )}</p>
                            
                            <div className="flex justify-end mb-6">
                                {canAddTools(user) && (
                                    <button
                                        onClick={() => setIsRequestModalOpen(true)}
                                        className="flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium
                                            bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                                    >
                                        <svg 
                                            className="w-5 h-5" 
                                            fill="none" 
                                            stroke="currentColor" 
                                            viewBox="0 0 24 24"
                                        >
                                            <path 
                                                strokeLinecap="round" 
                                                strokeLinejoin="round" 
                                                strokeWidth={2} 
                                                d="M12 6v6m0 0v6m0-6h6m-6 0H6" 
                                            />
                                        </svg>
                                        Add New Tool
                                    </button>
                                )}
                            </div>
                            
                            {user?.role < 1 && (
                                <div className="bg-yellow-50 dark:bg-yellow-900/20 border-l-4 border-yellow-500 px-4 pt-4 rounded-lg mb-6">
                                    <div className="flex items-start gap-3">
                                        <svg 
                                            className="w-5 h-5 text-yellow-600 dark:text-yellow-400 mt-0.5" 
                                            fill="none" 
                                            stroke="currentColor" 
                                            viewBox="0 0 24 24"
                                        >
                                            <path 
                                                strokeLinecap="round" 
                                                strokeLinejoin="round" 
                                                strokeWidth={2} 
                                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"                                            
                                                />
                                        </svg>
                                        <p className="text-yellow-700 dark:text-yellow-300">
                                            You currently don't have permission to rate tools. To gain access, please request access to the test which you can request via your dashboard.
                                        </p>
                                    </div>
                                </div>
                            )}
                            {/* Category Filter */}
                            <div className="mb-6">
                                <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 p-4">
                                    <div className="flex flex-col sm:flex-row gap-4">
                                        {/* Left column - Categories */}
                                        <div className="flex-grow sm:w-4/6">
                                            <div className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-3 flex items-center gap-2">
                                                Categories
                                                <select
                                                    value={isMultiSelect ? "multiple" : "single"}
                                                    onChange={(e) => setIsMultiSelect(e.target.value === "multiple")}
                                                    className="ml-2 text-xs font-medium px-2 py-1 rounded border border-gray-200 
                                                        dark:border-gray-700 bg-white dark:bg-gray-800 
                                                        focus:outline-none focus:ring-2 focus:ring-blue-500 
                                                        cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700
                                                        transition-colors duration-200"
                                                >
                                                    <option value="single">Single Select</option>
                                                    <option value="multiple">Multiple Select</option>
                                                </select>
                                            </div>
                                            <div className="flex flex-wrap gap-2">
                                                {["All" as CategoryType, ...CATEGORIES].map((category) => (
                                                    <button
                                                        key={category}
                                                        onClick={() => {
                                                            if (category === "All") {
                                                                setSelectedCategories(new Set(["All" as CategoryType]));
                                                            } else if (isMultiSelect) {
                                                                // Existing multi-select logic
                                                                setSelectedCategories(prev => {
                                                                    const next = new Set(prev);
                                                                    if (next.has("All" as CategoryType)) {
                                                                        next.clear();
                                                                    }
                                                                    if (next.has(category)) {
                                                                        next.delete(category);
                                                                        if (next.size === 0) {
                                                                            next.add("All" as CategoryType);
                                                                        }
                                                                    } else {
                                                                        next.add(category);
                                                                    }
                                                                    return next as SelectedCategories;
                                                                });
                                                            } else {
                                                                // Single select logic
                                                                setSelectedCategories(new Set([category] as CategoryType[]));
                                                            }
                                                        }}
                                                        className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
                                                            ${selectedCategories.has(category as CategoryType)
                                                                ? "bg-blue-600 text-white dark:bg-blue-500"
                                                                : "bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                                                            }`}
                                                    >
                                                        {category}
                                                    </button>
                                                ))}
                                            </div>
                                            <div className="mt-4 flex flex-col md:flex-row md:items-start md:justify-between">
                                                <div className="flex flex-col md:mr-4 max-w-xs"> {/* Set a max width for pricing */}
                                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                                        Pricing
                                                    </label>
                                                    <div className="flex flex-wrap gap-2">
                                                        {getAvailablePricingOptions().map((option) => (
                                                            <button
                                                                key={option.value}
                                                                onClick={() => setPricingFilter(option.value as Tool['pricing'])}
                                                                className={`px-3 py-1 rounded-md text-sm font-medium ${
                                                                    (option.value === '' && !pricingFilter) || pricingFilter === option.value
                                                                        ? "bg-blue-600 text-white dark:bg-blue-500"
                                                                        : "bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                                                                }`}
                                                            >
                                                                {option.label}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="mt-4 md:mt-0 md:ml-4 flex flex-col flex-grow mr-5"> {/* Allow search to grow */}
                                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                        Search
                                                    </label>
                                                    <div className="mt-2 relative">
                                                        <input 
                                                            type="text" 
                                                            value={searchQuery} 
                                                            onChange={(e) => setSearchQuery(e.target.value)} 
                                                            className="px-2 py-1 border rounded-md w-full bg-white dark:bg-gray-800 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 dark:text-white"
                                                            placeholder="Search tools..."
                                                        />
                                                        {searchQuery && (
                                                            <button
                                                                type="button"
                                                                className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                                                                onClick={() => setSearchQuery('')}
                                                            >
                                                                <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/></svg>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Right column - Sort and Search */}
                                        <div className="sm:w-48 space-y-4">
                                            {/* Sort by dropdown */}
                                            <div>
                                                <div className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-2">
                                                    Sort by
                                                </div>
                                                <select
                                                    value={sortBy}
                                                    onChange={(e) => setSortBy(e.target.value as SortOption)}
                                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                                                        bg-white dark:bg-gray-800 text-sm font-medium
                                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                                                        cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700
                                                        transition-colors duration-200"
                                                >
                                                    <option value="name">Alphabetical</option>
                                                    <option value="rating">Highest Rated</option>
                                                </select>
                                            </div>

                                            {/* Rating Type filter - now always visible */}
                                            <div>
                                                <div className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-2">
                                                    Rating Display
                                                </div>
                                                <select
                                                    value={ratingViewMode}
                                                    onChange={(e) => setRatingViewMode(e.target.value as RatingViewMode)}
                                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                                                        bg-white dark:bg-gray-800 text-sm font-medium
                                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                                                        cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700
                                                        transition-colors duration-200"
                                                >
                                                    <option value="average">Average Ratings</option>
                                                    <option value="our">Our Ratings</option>
                                                    <option value="user">Your Ratings</option>
                                                    {userName && profileUser && (
                                                        <option value="profile">{profileUser.name}'s Ratings</option>
                                                    )}
                                                </select>
                                            </div>

                                            {/* Add the new Rated/Unrated filter */}
                                            <div>
                                                <div className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-2">
                                                    Rating/Review Status
                                                </div>
                                                <select
                                                    value={ratedFilter}
                                                    onChange={(e) => setRatedFilter(e.target.value as RatedFilter)}
                                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                                                        bg-white dark:bg-gray-800 text-sm font-medium
                                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                                                        cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700
                                                        transition-colors duration-200"
                                                >
                                                    <option value="all">All Tools</option>
                                                    <option value="rated">Rated by Me</option>
                                                    <option value="unrated">Not Rated by Me</option>
                                                    <option value="personal" disabled={ratingViewMode === 'profile'}>My Personal Tools</option>
                                                    <option value="reviewed">My Reviewed Tools</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Results count */}
                                <div ref={resultsRef}>
                                    {searchQuery && (
                                        <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                                            Found {filteredTools.length} {filteredTools.length === 1 ? 'tool ' : 'tools '} 
                                            matching "{searchQuery}"
                                            {selectedCategories.has("All") ? "" : ` in ${Array.from(selectedCategories).join(", ")}`}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Show loading skeleton or content */}
                            {isLoading ? (
                                <LoadingSkeleton />
                            ) : (
                                renderContent()
                            )}
                        </>
                    }
                </div>
            </div>

            {/* Detailed View */}
            {detailedViewTool && (
                <DetailedView
                    tool={detailedViewTool}
                    onClose={() => setDetailedViewTool(null)}
                    onRatingSubmit={refreshTools}
                    onDelete={handleDeleteTool}
                    user={user}
                />
            )}

            {/* Add the modal at the end of your component */}
            {canAddTools(user) && (
                <RequestToolModal
                    isOpen={isRequestModalOpen}
                    onClose={() => setIsRequestModalOpen(false)}
                    onSubmit={handleToolRequest}
                />
            )}
        </section>
    );
}
export default Tools;
