import React from 'react';

const DashboardCard = ({ children, className = "", title, action }) => (
    <div className={`bg-white rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 overflow-hidden ${className}`}>
        {title && (
            <div className="h-14 px-6 pt-5 border-b border-gray-100 flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
                {action && <div className="flex items-center gap-2 mb-5">{action}</div>}
            </div>
        )}
        <div className="p-6">{children}</div>
    </div>
);

export default DashboardCard; 