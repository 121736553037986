import { useAuth } from '../../hooks/auth'
import {useEffect} from 'react'
import GuestLayout from "../../components/dashboard/Layouts/GuestLayout";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";

const Logout = () => {
    const { logout } = useAuth();

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Logout"
        logout()
            .then(() => window.location.href = '/')
            .catch(()  => {
               // Do nothing
            });
    }, [logout]);

    return (
        <GuestLayout className="flex flex-col items-center justify-center min-h-screen">
            <div className="max-w-md w-full mx-auto">
                <DashboardCard className="px-8 py-12">
                    <h2 className="text-3xl font-bold mb-4">Logging out...</h2>
                    <div className="flex items-center justify-center">
                        <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                </DashboardCard>
            </div>
        </GuestLayout>
    )
}

export default Logout
