import { useState } from "react";
import { CATEGORIES, CategoryBadge } from "../CategoryBadge/CategoryBadge";
import { InteractiveStarRating } from "./InteractiveStarRating";
import { ToolRequest } from "./types/ToolRequest";

export const RequestToolModal = ({ 
    isOpen, 
    onClose,
    onSubmit 
}: { 
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (request: ToolRequest) => Promise<void>;
}) => {
    const [formData, setFormData] = useState<ToolRequest>({
        name: '',
        url: '',
        desc: '',
        ratings: {}
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

    // Add function to reset form
    const resetForm = () => {
        setFormData({
            name: '',
            url: '',
            desc: '',
            ratings: {}
        });
        setSelectedCategory(null);
    };

    // Update close handler to reset form
    const handleClose = () => {
        resetForm();
        onClose();
    };

    const handleRatingChange = (category: string, value: number) => {
        setFormData(prev => ({
            ...prev,
            ratings: {
                ...prev.ratings,
                [category]: value
            }
        }));
        setSelectedCategory(null); // Reset selection after rating
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            await onSubmit(formData);
            resetForm(); // Reset form after successful submission
            onClose();
        } catch (error) {
            console.error('Error submitting tool request:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
            onClick={(e) => e.target === e.currentTarget && handleClose()}
        >
            <div className="bg-white dark:bg-gray-800 rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto p-6">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-bold text-gray-900 dark:text-white">Add New Tool</h2>
                    <p>Please make sure the tool is not already in the list!</p>
                    <button
                        onClick={handleClose}
                        className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                            Tool Name *
                        </label>
                        <input
                            type="text"
                            required
                            value={formData.name}
                            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                            className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                                bg-white dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                            Tool URL *
                        </label>
                        <input
                            type="url"
                            required
                            value={formData.url}
                            onChange={(e) => setFormData(prev => ({ ...prev, url: e.target.value }))}
                            className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                                bg-white dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                            Pricing
                        </label>
                        <select
                            value={formData.pricing}
                            onChange={(e) => setFormData(prev => ({
                                ...prev, 
                                pricing: e.target.value as "free" | "paid" | "both" | "trail" | "free-trail-paid"
                            }))}
                            className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                                bg-white dark:bg-gray-800 text-sm font-medium
                                focus:outline-none focus:ring-2 focus:ring-blue-500 
                                cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700
                                transition-colors duration-200"
                        >
                            <option value="">Select Pricing</option>
                            <option value="free">Free</option>
                            <option value="trail">Trial</option>
                            <option value="paid">Paid</option>
                            <option value="free-trail-paid">Free, Trail & Paid</option>
                            <option value="both">Free & Paid</option>
                            <option value="trail-paid">Trail & Paid</option>
                            <option value="">Unknown / Other</option>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                            Description *
                        </label>
                        <textarea
                            required
                            value={formData.desc}
                            onChange={(e) => setFormData(prev => ({ ...prev, desc: e.target.value }))}
                            className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 
                                bg-white dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            rows={3}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-4">
                            Rate Categories
                        </label>
                        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3">
                            {CATEGORIES.map((category) => (
                                <div 
                                    key={category}
                                    onClick={() => setSelectedCategory(category)}
                                    className={`p-3 rounded-lg cursor-pointer transition-all duration-200
                                        border ${selectedCategory === category 
                                            ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20' 
                                            : 'border-gray-200 dark:border-gray-700 hover:border-blue-300 dark:hover:border-blue-600'
                                        }
                                        ${formData.ratings[category] ? 'bg-green-50 dark:bg-green-900/20' : ''}`}
                                >
                                    <div className="flex items-center justify-between gap-2">
                                        <CategoryBadge category={category} />
                                        {formData.ratings[category] && (
                                            <div className="text-green-600 dark:text-green-400">
                                                {formData.ratings[category]}★
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Rating popup */}
                    {selectedCategory && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                            <div 
                                className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-sm w-full mx-4"
                                onClick={e => e.stopPropagation()}
                            >
                                <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                                    Rate {selectedCategory}
                                </h3>
                                <div className="flex justify-center mb-6">
                                    <InteractiveStarRating
                                        category={selectedCategory}
                                        rating={formData.ratings[selectedCategory] || 0}
                                        onChange={(value) => handleRatingChange(selectedCategory, value)}
                                    />
                                </div>
                                <button
                                    type="button"
                                    onClick={() => setSelectedCategory(null)}
                                    className="w-full px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300
                                        hover:text-gray-800 dark:hover:text-white transition-colors"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="flex justify-end gap-4 pt-4">
                        <button
                            type="button"
                            onClick={handleClose}
                            className="px-6 py-2 rounded-full text-sm font-medium
                                bg-gray-100 text-gray-600 hover:bg-gray-200
                                dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="px-6 py-2 rounded-full text-sm font-medium
                                bg-blue-600 text-white hover:bg-blue-700
                                disabled:bg-blue-400 disabled:cursor-not-allowed"
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit Request'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};