export const WarnLocalStorage = () => {
    return (
        <div className="col-span-12">
            <div className="bg-amber-50 dark:bg-amber-900/20 border border-amber-200 dark:border-amber-700 rounded-xl p-4">
                <div className="flex items-start gap-3">
                    <div className="p-1.5 bg-amber-100 dark:bg-amber-900/50 rounded-full shrink-0">
                        <svg className="w-5 h-5 text-amber-600 dark:text-amber-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-300">
                            Local Storage Only
                        </h3>
                        <p className="mt-1 text-amber-700 dark:text-amber-400">
                            Please note that prompts are currently only stored in your browser's local storage and will be lost if you clear your browser data or switch devices. We plan to add database storage in the future, but for now this demonstrates why storing prompts as components is very useful for organization and reuse.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}