// Components
import React, {useEffect} from "react";
import DocumentationNav from "./DocumentationNav";
import Pagination from "../../components/dashboard/dashboard/Pagination";

function UseCases() {

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Use Cases";
  }, []);

  // Dummy data for use cases
const useCases = [
    {
        id: 1,
        title: "Use Case 1",
        description: "This is a description of use case 1.",
        examplePrompt: "Example prompt for use case 1.",
        likes: 10,
        dislikes: 2,
    },
    {
        id: 2,
        title: "Use Case 2",
        description: "This is a description of use case 2.",
        examplePrompt: "Example prompt for use case 2.",
        likes: 5,
        dislikes: 1,
    },
    // Add more dummy use cases as needed
];

const [currentPage, setCurrentPage] = React.useState(1);
const itemsPerPage = 6; // Set the number of items per page
const totalPages = Math.ceil(useCases.length / itemsPerPage);

// Calculate the index of the first and last items on the current page
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentUseCases = useCases.slice(indexOfFirstItem, indexOfLastItem);

  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="llms">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="UseCases" />

              <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="explain-llms"
                   data-aos="fade-up"
                   data-aos-delay="100">
                <h1 className="text-bold"><span className="text-header-gradient">Use Cases</span></h1>
                <p>Share your use cases for your favorite tool(s), and help others in the community!</p>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {currentUseCases.map((useCase) => (
                        <div key={useCase.id} className="bg-white shadow-lg rounded-lg p-4 transition-transform transform hover:scale-105">
                            <h2 className="text-xl font-semibold text-gray-800">{useCase.title}</h2>
                            <p className="text-gray-700 mb-2">{useCase.description}</p>
                            <p className="text-gray-500 italic mb-2">Example Prompt: <span className="font-medium">{useCase.examplePrompt}</span></p>
                            <div className="flex items-center">
                                <button className="bg-blue-500 text-white px-2 py-1 rounded-md mr-2 hover:bg-blue-600 transition">👍 {useCase.likes}</button>
                                <button className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600 transition">👎 {useCase.dislikes}</button>
                            </div>
                        </div>
                    ))}
                </div>

            {/* Pagination Component */}
            <Pagination 
                currentPage={currentPage} 
                totalPages={totalPages} 
                onPageChange={setCurrentPage} 
            />
        </div>

        </div>
      </section>
  );
}

export default UseCases;
