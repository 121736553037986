import React from 'react';
import { GridItem } from './types/GridItem';
import { Recipe } from './types/Recipe';
import { ViewMode } from './types/ViewMode';
import { SectionHeader } from './SectionHeader';
import { NoPromptFound } from './NoPromptFound';
import { PromptCard } from './PromptCard';
import { PromptRecipeCard } from './PromptRecipeCard';
import { Section } from './types/Section';

interface ShowNoSectionsProps {
    viewMode: ViewMode;
    copiedId: string | null;
    mergedPrompts: GridItem[];
    tools: any[];
    recipes: Recipe[];
    handleCopy: (prompt: string, id: string) => void;
    handleAddToMerged: (prompt: GridItem) => void;
    handleDeletePrompt: (id: string) => void;
    handleOpenModal: (section: Section) => void;
    handleLoadRecipe: (recipe: Recipe) => void;
    toggleRecipePublish: (recipeId: string) => void;
    handleDeleteRecipe: (recipeId: string) => void;
    getAllItems: () => GridItem[];
    getFilteredRecipes: () => Recipe[];
}

export const ShowNoSections: React.FC<ShowNoSectionsProps> = ({
    viewMode,
    copiedId,
    mergedPrompts,
    tools,
    recipes,
    handleCopy,
    handleAddToMerged,
    handleDeletePrompt,
    handleOpenModal,
    handleLoadRecipe,
    toggleRecipePublish,
    handleDeleteRecipe,
    getAllItems,
    getFilteredRecipes
}) => {
    return (
        <div className="space-y-8 mt-8">
            {getAllItems().length > 0 ? (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                        {getAllItems()
                            .filter(item => !item.isRecipe)
                            .map((item: GridItem) => (
                                <PromptCard
                                    key={item.id}
                                    prompt={item}
                                    viewMode={viewMode}
                                    copiedId={copiedId}
                                    mergedPrompts={mergedPrompts}
                                    tools={tools}
                                    handleCopy={handleCopy}
                                    handleAddToMerged={handleAddToMerged}
                                    handleDeletePrompt={handleDeletePrompt}
                                />
                            ))}
                    </div>
                    
                    {/* Recipes section on new row */}
                    {getFilteredRecipes().length > 0 && (
                        <div className="w-full col-span-full">
                            <div className="space-y-3">
                                <SectionHeader section="Recipes" />
                                <PromptRecipeCard
                                    recipes={recipes}
                                    viewMode={viewMode}
                                    copiedId={copiedId}
                                    handleCopy={handleCopy}
                                    handleLoadRecipe={handleLoadRecipe}
                                    toggleRecipePublish={toggleRecipePublish}
                                    handleDeleteRecipe={handleDeleteRecipe}
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <NoPromptFound handleOpenModal={handleOpenModal} />
            )}
        </div>
    );
};