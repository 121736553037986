import React from 'react';

const StatusLabel = ({ status }) => {
    const getStatusConfig = (status) => {
        switch (status) {
            case 0:
                return {
                    label: 'Open',
                    className: 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400'
                };
            case 1:
                return {
                    label: 'In Progress',
                    className: 'bg-orange-100 text-orange-800 dark:bg-orange-900/30 dark:text-orange-400'
                };
            case 2:
                return {
                    label: 'Closed',
                    className: 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-400'
                };
            default:
                return {
                    label: 'Unknown',
                    className: 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-400'
                };
        }
    };

    const config = getStatusConfig(status);

    return (
        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${config.className}`}>
            {config.label}
        </span>
    );
};

export default StatusLabel; 